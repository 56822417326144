import React from 'react';
import {
  Card,
  CardHeader,
  Avatar,
  IconButton,
  CardContent,
  Typography
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  card: {
    // maxWidth: 345,
    margin: theme.spacing(2)
  },
  media: {
    height: 190
  }
}));

const data = [
  {
    id: 1,
    initials: 'HK',
    name: 'Hannes Karask',
    body: (
      <>
        Hannes Karask is getting the next round of beers for smashing through{' '}
        <strong>Object Oriented Programming Fundamentals Csharp</strong> 🎉
      </>
    )
  },
  {
    id: 2,
    initials: 'AK',
    name: 'Anu Käver',
    body: (
      <>
        Anu Käver has just mastered{' '}
        <strong>Asynchronous Programming Dotnet Getting Started</strong> 👏
      </>
    )
  },
  {
    id: 3,
    initials: 'FB',
    name: 'Foo Bar',
    body: (
      <>
        Foo Bar is the new guru of <strong>Dotnet Micro Orms Introduction</strong> 👏
      </>
    )
  },
  {
    id: 4,
    initials: 'AK',
    name: 'Anu Käver',
    body: (
      <>
        Anu Käver has just mastered{' '}
        <strong>Asynchronous Programming Dotnet Getting Started</strong> 🐱‍🏍
      </>
    )
  },
  {
    id: 5,
    initials: 'HK',
    name: 'Hannes Karask',
    body: (
      <>
        Hannes Karask just smashed through{' '}
        <strong>Object Oriented Programming Fundamentals Csharp</strong> 😎
      </>
    )
  }
];

const Activity = () => {
  const classes = useStyles();

  return (
    <>
      {data.map(d => (
        <Card key={d.id} className={classes.card}>
          <CardHeader
            avatar={<Avatar alt="Hannes Karask">{d.initials}</Avatar>}
            action={
              <IconButton aria-label="settings">
                <MoreVertIcon />
              </IconButton>
            }
            title={d.name}
            subheader={'5 hours ago'}
          />
          <CardContent>
            <Typography variant="body1" color="textSecondary">
              {d.body}
            </Typography>
          </CardContent>
        </Card>
      ))}
    </>
  );
};

export default Activity;
