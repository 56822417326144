import React from 'react';
import { Typography, Toolbar, Box } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import useDashboardContext from '../useDashboardContext';
import LoadingSkeleton from '../../../components/LoadingSkeleton';
import useAppContext from '../../../hooks/useAppContext';
import Goal from './Goal';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: 24
    },
    listIcon: { minWidth: 34 },
    listTextDone: { textDecoration: 'line-through', color: theme.palette.grey[500] },
    actions: { visibility: 'hidden' },
    row: {
      '&:hover .actions': {
        visibility: 'visible'
      }
    }
  })
);

const Goals = () => {
  const { subscribeOnCourseUpdate, unSubscribeOnCourseUpdate } = useAppContext();
  const { goalsRequest } = useDashboardContext();
  const classes = useStyles();

  React.useEffect(() => {
    const fn = (courseId: string) => {
      if (goalsRequest.result?.find((g) => g.course!.id === courseId))
        goalsRequest.execute();
    };
    subscribeOnCourseUpdate(fn);
    return () => {
      unSubscribeOnCourseUpdate(fn);
    };
  }, [goalsRequest, subscribeOnCourseUpdate, unSubscribeOnCourseUpdate]);

  return (
    <>
      <Toolbar>
        <Typography variant="h6">My Learning Goals</Typography>
      </Toolbar>
      <Box className={classes.root}>
        {goalsRequest.loading && !goalsRequest.result && <LoadingSkeleton variant="list" />}
        {!goalsRequest.loading && goalsRequest.result?.length === 0 && (
          <Box mb={3}>No goals yet. Add them from the course's detailed view.</Box>
        )}
        {!goalsRequest.loading && goalsRequest.errorMessage && (
          <Box mb={3}>Problem loading goals :(</Box>
        )}
        {goalsRequest.result?.map((goal) => (
          <Goal key={goal.id} goal={goal} />
        ))}
      </Box>
    </>
  );
};

export default Goals;
