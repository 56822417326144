import React from 'react';
import {
  Dashboard as DashboardIcon,
  AccountBox as AccountIcon,
  Settings as SettingsIcon,
  EmojiPeople as EmojiPeopleIcon,
  LinearScale as LinearScaleIcon
} from '@material-ui/icons';

export interface SitemapItem {
  title: string;
  href?: string;
  icon?: JSX.Element;
}

export interface PagesProps {
  hasAccessToUsers: boolean;
  hasAccessToPaths: boolean;
}
export const getPages = ({
  hasAccessToUsers,
  hasAccessToPaths
}: PagesProps): SitemapItem[] => {
  const pages = [
    {
      title: 'Dashboard',
      href: '/dashboard',
      icon: <DashboardIcon />
    },
    {
      title: 'Account',
      href: '/account',
      icon: <AccountIcon />
    },
    {
      title: 'Settings',
      href: '/settings',
      icon: <SettingsIcon />
    }
  ];
  if (hasAccessToUsers) {
    pages.push({
      title: 'People',
      href: '/admin/users',
      icon: <EmojiPeopleIcon />
    });
  }

  if (hasAccessToPaths) {
    pages.push({
      title: 'Paths',
      href: '/admin/paths',
      icon: <LinearScaleIcon />
    });
  }

  return pages;
};
