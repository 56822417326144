import React from 'react';
import PathProvider from './PathProvider';
import Paths from './Paths';

const index = () => {
  return (
    <PathProvider>
      <Paths />
    </PathProvider>
  );
};

export default index;
