import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Avatar, Typography } from '@material-ui/core';
import { deepOrange } from '@material-ui/core/colors';
import useAppContext from '../../hooks/useAppContext';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60,
    color: '#fff',
    backgroundColor: deepOrange[500]
  },
  name: {
    marginTop: theme.spacing(1)
  }
}));

const Profile = (props: any) => {
  const { className, ...rest } = props;
  const { profile } = useAppContext();
  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Avatar
        alt="Person"
        className={classes.avatar}
        component={RouterLink}
        to="/settings"
      >
        {profile ? `${profile.firstName[0]}${profile.lastName[0]}` : 'U'}
      </Avatar>
      {profile && (
        <>
          <Typography className={classes.name} variant="h4">
            {profile?.fullName}
          </Typography>
          <Typography variant="body2" align="center">
            {profile?.position}
          </Typography>
          <Typography variant="body2" align="center">
            {profile?.roles.length > 0 && <>[{profile?.roles.join(', ')}]</>}
          </Typography>{' '}
        </>
      )}
    </div>
  );
};

export default Profile;
