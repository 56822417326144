import React from 'react';
import {
  Table,
  TableRow,
  TableCell,
  Dialog,
  DialogTitle,
  TableBody,
  TableHead
} from '@material-ui/core';
import { UserCourseDto } from '../../../api/coach.generated';
import LoadingSkeleton from '../../../components/LoadingSkeleton';
import Row from './Row';


const UserPathPreview = ({
  open,
  setOpen,
  courseInfos,
  pathName,
  user
}: {
  open: boolean;
  setOpen: (val: boolean) => void;
  courseInfos: UserCourseDto[];
  pathName?: string;
  user?: { id: string, name: string };
}) => {
  return (
    <Dialog
      onClose={() => setOpen(false)}
      aria-labelledby="simple-dialog-title"
      open={open}
      maxWidth="lg"
    >
      <DialogTitle id="simple-dialog-title">
        {user?.name} {'>'} {pathName}
      </DialogTitle>
      {courseInfos?.length ? (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Started</TableCell>
              <TableCell>Completed</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Goal</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {user && courseInfos.map((ci) => (
              <Row key={ci.id} course={ci} user={user} />
            ))}
          </TableBody>
        </Table>
      ) : (
        <LoadingSkeleton variant="table" />
      )}
    </Dialog>
  );
};

export default UserPathPreview;
