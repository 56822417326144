import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Course from './Course';
import { UserCourseDto } from '../../../api/coach.generated';
import { DashboardApi } from '../../../api/coachApi';
import Actions from './Actions';
import LoadingSkeleton from '../../../components/LoadingSkeleton';
import useDashboardContext from '../useDashboardContext';
import useAppContext from '../../../hooks/useAppContext';
import { Box } from '@material-ui/core';
import useRequest from '../../../hooks/useRequest';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 20
  }
}));

export default function Courses({
  skillId,
  count
}: {
  skillId: string;
  count: number;
}) {
  const { subscribeOnCourseUpdate, unSubscribeOnCourseUpdate, profile } = useAppContext();
  const { goalsRequest, pathsRequest, selectedPath } = useDashboardContext();
  const classes = useStyles();


  const getCourses = React.useCallback(
    () => DashboardApi.courses(selectedPath?.pathId!, skillId),
    [selectedPath, skillId]
  );

  const coursesRequest = useRequest(getCourses);

  React.useEffect(() => {
    const fn = (courseId: string) => {
      if (coursesRequest.result?.find((c) => c.id === courseId)) coursesRequest.execute();
    };
    subscribeOnCourseUpdate(fn);
    return () => {
      unSubscribeOnCourseUpdate(fn);
    };
  }, [coursesRequest, subscribeOnCourseUpdate, unSubscribeOnCourseUpdate]);

  const onCourseUpdated = (course: UserCourseDto) => {
    goalsRequest.execute();
    pathsRequest.execute();
  };
  const onGoalRemoved = () => {
    goalsRequest.execute();
    coursesRequest.execute();
  };

  return (
    <div className={classes.root}>
      <div>
        {coursesRequest.initialLoading && <LoadingSkeleton variant="list" rows={count} />}
        {!coursesRequest.loading && coursesRequest.result?.length === 0 && (
          <span>No courses</span>
        )}
        {coursesRequest.result?.map((course) => (
          <Box key={course.id} mb={2}>
            {profile && (
              <Course
                course={course}
                hideSkillAndRank
                actions={
                  <Actions
                    course={course}
                    onCourseUpdated={onCourseUpdated}
                    onGoalRemoved={onGoalRemoved}
                  />
                }
              />
            )}
          </Box>
        ))}
      </div>
    </div>
  );
}
