import React from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Button, Typography } from '@material-ui/core';
import useStyles from './useStyles';
import { LoginRequest } from '../../api/coach.generated';
import { AuthApi } from '../../api/coachApi';
import { useFormHandler } from '../../helpers';
import { FormHandler } from '../../helpers/FormHandler';
import { session } from '../../helpers';
import useIsMounted from '../../hooks/useIsMounted';

const SignIn = (props: any) => {
  const isMounted = useIsMounted();
  const { history } = props;
  const [loginEnabled, setLoginEnabled] = React.useState(true);

  const formHandler: FormHandler<LoginRequest> = useFormHandler({
    model: {} as LoginRequest,
    endpoint: () =>
      AuthApi.login({
        email: formHandler.state.model.email,
        password: formHandler.state.model.password
      }),
    onSuccess: (response) => {
      session.setUserId(response.userId);
      return history.push('/dashboard');
    }
  });

  const classes = useStyles();

  const handleSignIn = async (event: any) => {
    event.preventDefault();
    setLoginEnabled(false);
    await formHandler.execute();
    !formHandler.state.success && isMounted && setLoginEnabled(true);
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography className={classes.quoteText} variant="h1">
                Knowledge awaits you!
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSignIn}>
                <Typography className={classes.title} variant="h2">
                  Sign in
                </Typography>
                {formHandler.GenericError()}
                {formHandler.FormField({
                  propName: 'email',
                  label: 'Email address',
                  type: 'text'
                })}
                {formHandler.FormField({
                  propName: 'password',
                  label: 'Password',
                  type: 'password'
                })}
                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={!loginEnabled || !formHandler.state.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Sign in now
                </Button>
                <a href="/recover-password">Forgot password</a>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(SignIn);
