import React from 'react';
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers';
import EventIcon from '@material-ui/icons/Event';
import { UsersApi } from '../../api/coachApi';
import { Grid, Tooltip, withStyles } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import useAppContext from '../../hooks/useAppContext';

const adjustTimeOffset = (date: Date | null): Date | null => {
  if (!date) return date;
  const offset = -date.getTimezoneOffset();
  date.setHours(Math.trunc(offset / 60), offset % 60);
  return date;
};

const CustomDatePicker = withStyles({
  root: {
    '& .MuiInput-underline:before': {
      border: '0 !important'
    },
    '& .MuiInput-underline:after': {
      border: '0 !important'
    }
  }
})(DatePicker);

const DueDate = ({ goalId, dueDate }: { goalId: string; dueDate?: Date | undefined }) => {
  const { profile } = useAppContext();
  const [isOpen, setIsOpen] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(dueDate ?? null);
  const pastGoal = selectedDate && new Date(selectedDate) < new Date();
  const expiredColor = red[700];

  const handleDateChange = (date: Date | null) => {
    const adjustedDate = adjustTimeOffset(date);
    setSelectedDate(adjustedDate);
    UsersApi.setGoalDueDate(profile!.id, goalId, { dueDate: adjustedDate ?? undefined });
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Tooltip title="Due date">
        <Grid container spacing={1} alignItems="flex-end">
          <Grid item>
            <EventIcon
              onClick={() => setIsOpen(true)}
              color={selectedDate ? 'action' : 'disabled'}
              style={{ color: pastGoal ? expiredColor : undefined }}
            />
          </Grid>
          <Grid item>
            <CustomDatePicker
              format="dd.MM.yyyy"
              value={selectedDate}
              onChange={handleDateChange}
              open={isOpen}
              onOpen={() => setIsOpen(true)}
              onClose={() => setIsOpen(false)}
              style={{ width: 74 }}
              InputProps={{
                style: pastGoal ? { color: expiredColor } : undefined
              }}
              placeholder="__ . __ . ___"
            />
          </Grid>
        </Grid>
      </Tooltip>
    </MuiPickersUtilsProvider>
  );
};

export default DueDate;
