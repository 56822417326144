import React from 'react';
import { DashboardContext } from './DashboardProvider';

const useDashboardContext = () => {
  const context = React.useContext(DashboardContext);
  if (!context) {
    throw new Error('useDashboardContext must be used within a DashboardProvider');
  }
  return context;
};

export default useDashboardContext;
