import React from 'react';
import clsx from 'clsx';
import { Divider, Drawer } from '@material-ui/core';
import SidebarNav from './SidebarNav';
import { styles } from './Sidebar.styles';
import Profile from './Profile';
import { getPages, SitemapItem } from './sitemap';
import { Rights } from '../../api/coach.generated';
import useAppContext from '../../hooks/useAppContext';

const Sidebar = (props: any) => {
  const appContext = useAppContext();
  const { open, variant, onClose, className, ...rest } = props;
  const classes = styles();

  const [pages, setPages] = React.useState<SitemapItem[]>([]);

  React.useEffect(() => {
    if (!appContext.accessList) return;

    const pages = getPages({
      hasAccessToUsers: appContext.accessList.users > Rights.None,
      hasAccessToPaths: appContext.accessList.paths > Rights.None
    });

    setPages(pages);
  }, [appContext.accessList]);

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <Profile />
        <Divider className={classes.divider} />
        <SidebarNav className={classes.nav} pages={pages} />
      </div>
    </Drawer>
  );
};

export default Sidebar;
