import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { ToastContainer } from '../components/Toaster';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 64,
    height: '100%'
  },
  content: {
    height: '100%'
  }
}));

const MinimalLayout = (props: any) => {
  const { children } = props;

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Topbar />
      <main className={classes.content}>{children}</main>
      <ToastContainer />
    </div>
  );
};

const useStylesTopBar = makeStyles(() => ({
  root: {
    boxShadow: 'none'
  },
  logoLink: {
    display: 'flex',
    alignItems: 'center',
    // backgroundColor: '#F4F6F8',
    width: 214,
    height: 64,
    // zIndex: 1,
    // paddingLeft: 29,
    // position: 'relative',
    // left: -24,
    textDecoration: 'none',
    fontSize: 33,
    // color: '#263238',
    color: '#fff',
    fontWeight: 'bold'
  }
}));

const Topbar = (props: any) => {
  const { className, ...rest } = props;

  const classes = useStylesTopBar();

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
      position="fixed"
    >
      <Toolbar>
        <RouterLink
          to={document.location.href.indexOf('sign-in') > 0 ? '#' : '/'}
          className={classes.logoLink}
        >
          Coach
          <img alt="Logo" src="/images/logos/whistle-white.svg" style={{ width: 40 }} />
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

export default MinimalLayout;
