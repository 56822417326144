import React from 'react';
import { EditOutlined as EditOutlinedIcon } from '@material-ui/icons';
import { makeStyles, Typography, Box, Button } from '@material-ui/core';
import { CourseInfo } from '../../../api/coach.generated';
import CourseDetails from '../../../components/CourseDetails';
import RemoveCourse from '../../../components/Path/RemoveCourse';
import {
  useParams,
  Link as RouterLink,
  LinkProps as RouterLinkProps
} from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    cursor: 'pointer'
  },
  iconContainer: {
    flexShrink: 0, // Fix IE 11 issue
    display: 'flex',
    paddingRight: 8
  },
  labelContainer: {
    width: '100%'
  },
  text: {
    fill: theme.palette.primary.contrastText,
    fontSize: theme.typography.caption.fontSize,
    fontFamily: theme.typography.fontFamily
  },
  content: {
    marginTop: 8,
    borderLeft: '1px solid #bdbdbd',
    marginLeft: 12,
    paddingLeft: 20,
    paddingRight: 8
  }
}));

const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => (
  <RouterLink innerRef={ref} {...props} />
));

const Course = ({
  course,
  canEdit,
  onRemoved
}: {
  course: CourseInfo;
  canEdit: boolean;
  onRemoved(courseId: string): void;
}) => {
  const classes = useStyles();
  const { pathSlug } = useParams<{ pathSlug: string }>();
  const [open, setOpen] = React.useState(false);

  const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation();
    setOpen((open) => !open);
  };

  return (
    <Box my={1}>
      <div className={classes.root} onClick={onClick}>
        <span className={classes.labelContainer}>
          <Typography variant="body2" component="span">
            <CourseDetails course={course} hideSkillAndRank={true} />
          </Typography>
        </span>
      </div>
      <div className={classes.content}>
        {open && (
          <>
            <Typography>
              <a href={course.url} target="_blank" rel="noopener noreferrer">
                {course.url}
              </a>
            </Typography>
            {canEdit && (
              <div>
                <Button
                  startIcon={<EditOutlinedIcon />}
                  component={Link}
                  to={`/admin/paths/${pathSlug}/editCourse/${course.id}`}
                >
                  Edit
                </Button>
                <RemoveCourse
                  pathId={course.pathId}
                  courseId={course.id}
                  onRemoved={() => onRemoved(course.id)}
                />
              </div>
            )}
          </>
        )}
      </div>
    </Box>
  );
};

export default Course;
