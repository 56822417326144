import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, Badge, Hidden, IconButton, Tooltip } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import ExitIcon from '@material-ui/icons/ExitToApp';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';

/*
<div>Icons made by <a href="https://www.flaticon.com/authors/nikita-golubev" 
  title="Nikita Golubev">Nikita Golubev</a> from 
  <a href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a>
</div>

https://www.flaticon.com/free-icon/whistle_289429
*/

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  logoLink: {
    display: 'flex',
    alignItems: 'center',
    // backgroundColor: '#F4F6F8',
    width: 214,
    height: 64,
    // zIndex: 1,
    // paddingLeft: 29,
    // position: 'relative',
    // left: -24,
    textDecoration: 'none',
    fontSize: 33,
    // color: '#263238',
    color: '#fff',
    fontWeight: 'bold'
  }
}));

const Topbar = (props: any) => {
  const { className, onSidebarOpen, ...rest } = props;

  const classes = useStyles();

  const [notifications] = useState([]);

  // https://material-ui.com/guides/composition/#routing-libraries
  const LoginLink = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
  ));

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <RouterLink to="/" className={classes.logoLink}>
          Coach
          <img
            alt="Logo"
            src="/images/logos/whistle-white.svg"
            style={{ width: 40, marginLeft: 5 }}
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden mdDown>
          <IconButton color="inherit">
            <Badge badgeContent={notifications.length} color="primary" variant="dot">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          {/* TODO: refactor to a helper method */}
          <IconButton
            className={classes.signOutButton}
            component={LoginLink}
            to="/logout"
            color="inherit"
          >
            <Tooltip title="Log out">
              <ExitIcon />
            </Tooltip>
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default Topbar;
