import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '20px 40px 10px'
  }
}));

const Footer = (props: any) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography variant="body1" style={{ float: 'left' }}>
        &copy;{' '}
        <Link component="a" href="#" target="_blank">
          Coach
        </Link>{' '}
        2019
      </Typography>
      <Typography variant="caption" style={{ float: 'right' }}>
        {' '}
        Scientia potentia est
      </Typography>
    </div>
  );
};

export default Footer;
