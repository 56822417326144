import * as React from 'react';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  List,
  makeStyles,
  Tooltip,
  Badge,
  withStyles,
  createStyles,
  Theme,
  styled,
  useTheme
} from '@material-ui/core';

import {
  Group as GroupIcon,
  Extension as ExtensionIcon,
  Stars as StarsIcon,
  CheckCircle as CheckCircleIcon,
  PanoramaFishEye as PanoramaFishEyeIcon,
  TrackChanges as TrackChangesIcon
} from '@material-ui/icons';

import { UserStatsByPathDto } from '../../api/coach.generated';
import { green, grey, deepOrange } from '@material-ui/core/colors';

const useStyles = makeStyles((theme) => ({
  listItem: {
    padding: 0
  },
  listIcon: {
    minWidth: 0,
    marginRight: 4
  },
  listText: {
    marginTop: 7
  },
  goalsLabel: {
    //display: 'inline-block',
    fontSize: '1rem',
    fontWeight: 'bold',
    position: 'relative',
    top: 2,
    paddingBottom: theme.spacing(1)
  }
}));

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    root: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1)
    },
    badge: {
      right: -3,
      top: 8,
      border: `1px solid ${theme.palette.background.paper}`,
      backgroundColor: deepOrange[500],
      padding: '0 4px',
      height: 18,
      minWidth: 18
    }
  })
)(Badge);

const MiniProfile = ({
  profile,
  onGoalsClick
}: {
  profile: UserStatsByPathDto;
  onGoalsClick(e: React.MouseEvent): void;
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const DoneIcon = styled(CheckCircleIcon)({ color: green[500] });
  const ActiveIcon = styled(TrackChangesIcon)({ color: theme.palette.primary.main });
  const NotDoneIcon = styled(PanoramaFishEyeIcon)({ color: grey[500] });

  return (
    <List>
      <ListItem className={classes.listItem}>
        <b>
          {profile.firstName} {profile.lastName}
        </b>
      </ListItem>
      {!!profile.roles?.length && (
        <ListItem className={classes.listItem}>[{profile.roles}]</ListItem>
      )}
      <ListItem className={classes.listItem}>
        <Tooltip title="Role">
          <ListItemIcon className={classes.listIcon}>
            <ExtensionIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText className={classes.listText}>
          {profile.position || 'NA'}
        </ListItemText>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip title="Team">
          <ListItemIcon className={classes.listIcon}>
            <GroupIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText className={classes.listText}>{profile.team || 'NA'}</ListItemText>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip title="Reports to">
          <ListItemIcon className={classes.listIcon}>
            <StarsIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText className={classes.listText}>
          {profile.reportsTo || 'NA'}
        </ListItemText>
      </ListItem>
      {profile.goalStats && (
        <ListItem
          className={classes.listItem}
          style={{ padding: theme.spacing(1) }}
          button
          onClick={onGoalsClick}
        >
          <ListItemText className={classes.listText}>
            <div className={classes.goalsLabel}>Goals:</div>
            <Tooltip title="Todo">
              <StyledBadge badgeContent={profile.goalStats.todo} color="primary" showZero>
                <NotDoneIcon />
              </StyledBadge>
            </Tooltip>
            <Tooltip title="In Progess">
              <StyledBadge
                badgeContent={profile.goalStats.inProgress}
                color="primary"
                showZero
              >
                <ActiveIcon />
              </StyledBadge>
            </Tooltip>
            <Tooltip title="Done">
              <StyledBadge
                badgeContent={profile.goalStats.completed}
                color="primary"
                showZero
              >
                <DoneIcon />
              </StyledBadge>
            </Tooltip>
          </ListItemText>
        </ListItem>
      )}
    </List>
  );
};

export default MiniProfile;
