import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { Dialog, Slide, Box } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';
import CoachDialogTitle from './CoachDialogTitle';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface CustomDialogProps {
  title: string;
  onClose: () => void;
  open: boolean;
  children: React.ReactNode;
  /** Loading content, skeleton */
  loading?: boolean;
  /** Saving, spinner in top left */
  inProgress?: boolean;
}

const CoachDialogWithTitle = ({
  title,
  onClose,
  open,
  children,
  loading,
  inProgress
}: CustomDialogProps) => {
  return (
    <Dialog
      fullWidth
      onClose={onClose}
      aria-labelledby="simple-dialog-title"
      open={open}
      TransitionComponent={Transition}
    >
      <CoachDialogTitle title={title} onClose={onClose} inProgress={inProgress} />
      {loading ? (
        <Box style={{ margin: 30 }}>
          <Skeleton height={60} width={500} />
          <Skeleton height={60} width="60%" />
          <Skeleton height={60} width="80%" />
        </Box>
      ) : (
        children
      )}
      {/* Pass content and action in as children */}
      {/* <DialogContent>{children}</DialogContent> */}
      {/* <CoachDialogActions onSubmit={onSubmit} actionInProgress={actionInProgress} /> */}
    </Dialog>
  );
};

export default CoachDialogWithTitle;
