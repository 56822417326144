import React from 'react';
import Skill from './Skill';
import useDashboardContext from '../useDashboardContext';

const Skills = () => {
  const { skillsRequest } = useDashboardContext();

  return (
    <div style={{ paddingBottom: 20 , opacity: skillsRequest.loading ? 0.2 : 1 }}>
      {skillsRequest.result?.map((skill) => (
          <Skill key={skill.id} skill={skill} />
        ))}
    </div>
  );
};

export default Skills;
