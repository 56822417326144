import React from 'react';
import { SwaggerException } from '../api/coach.generated';
import { toast } from '../components/Toaster';
import useIsMounted from './useIsMounted';

export type Request<T> = {
  /**
   * Function to fetch result
   */
  execute: (...args: any[]) => Promise<T>;

  /**
   * Fetched result
   */
  result: T;

  /**
   * Every time the result gets loaded
   */
  loading: boolean;

  /**
   * First time it loads (no prior result)
   */
  initialLoading: boolean;
  errorMessage: string | null;
};

/**
 * One stop shop for handling fetch requests
 * @param func function pointer hitting an API
 * @param params optional: turn on lazy (for mutating commands) and set a success message
 */
const useRequest = <T>(
  func: (...args: any[]) => Promise<T>,
  params?: {
    lazy?: boolean;
    successMessage?: string;
  }
): Request<T> => {
  const isMounted = useIsMounted();
  const [result, setResult] = React.useState<T>(null!);
  const [loading, setLoading] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const execute = React.useCallback(
    async (...args: any[]) => {
      let returnValue: T = null!;
      setLoading(true);
      try {
        const res = await func(...args);
        //console.info('🔵 func', `${func}`.replace(/__WEBPACK_IMPORTED_MODULE_\d*__/, ''));
        returnValue = res;
        isMounted && setResult(res);
        params?.successMessage &&
          toast({ message: params.successMessage, variant: 'success' });
      } catch (error) {
        let message = 'Unexpected error, please try again';
        console.error(error);
        if (error instanceof SwaggerException) {
          //TODO:
          //const responseObj: ValidationProblemDetails = JSON.parse(error.response);
          message = `${error.status}: ${error.message}`;
        }
        isMounted && setErrorMessage(message);
        toast({ message, variant: 'error' });
      } finally {
        setLoading(false);
      }
      return returnValue;
    },
    [func, params?.successMessage, isMounted]
  );

  React.useEffect(() => {
    !params?.lazy && execute();
  }, [execute, params?.lazy]);

  return {
    execute,
    result,
    loading,
    initialLoading: !result && loading,
    errorMessage
  };
};

export default useRequest;
