import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

// import AccountDetails from './AccountDetails';
import AccountProfile from './AccountProfile';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Account = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item md={12}>
          <AccountProfile />
        </Grid>
        {/* <Grid item lg={8} md={6} xl={8} xs={12}>
          <AccountDetails />
        </Grid> */}
      </Grid>
    </div>
  );
};

export default Account;
