import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import Password from './Password';
// import Notifications from './Notifications';
// import Pluralsight from './Pluralsight';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Settings = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        {/* <Grid item md={7} xs={12}>
          <Notifications />
        </Grid> */}
        <Grid item md={6} xs={12}>
          <Password />
        </Grid>
        {/* <Grid item md={6} xs={12}>
          <Pluralsight />
        </Grid> */}
      </Grid>
    </div>
  );
};

export default Settings;
