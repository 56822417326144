// https://create-react-app.dev/docs/proxying-api-requests-in-development
// https://react-material-dashboard.devias.io/products
// https://css-tricks.com/snippets/css/a-guide-to-flexbox/
// https://github.com/InfoTrackGlobal/HomeScreen
// https://www.robinwieruch.de/react-hooks-fetch-data
// https://material-ui.com/components/
// https://levelup.gitconnected.com/usetypescript-a-complete-guide-to-react-hooks-and-typescript-db1858d1fb9c
// https://dbdiagram.io/d/5d86bd48ff5115114db47eba
// https://www.typescriptlang.org/play
// https://basarat.gitbooks.io/typescript/docs/types/index-signatures.html

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import * as serviceWorker from './serviceWorker';
// import Dashboard from "./SampleDash/Dashboard";
import App from './App';
import { LoggerApi } from './api/coachApi';

const sendErrorToBackend = (message: string) => {
  LoggerApi.error(message + window?.location?.href);
};

window.addEventListener('unhandledrejection', function (promiseRejectionEvent) {
  sendErrorToBackend(promiseRejectionEvent.reason);
});
window.onerror = function (...args: any[]) {
  sendErrorToBackend(args.filter(Boolean).join('\n'));
};

ReactDOM.render(<App />, document.getElementById('root'));
// ReactDOM.render(<Dashboard />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
