import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Divider, Typography } from '@material-ui/core';
import { Route, useHistory, useParams } from 'react-router-dom';
import { PathMetaResponse, Rights } from '../../api/coach.generated';
import AddPath from './Add/Add';
import AddCourse from '../../components/Course/Add';
import EditCourse from './Courses/Edit';
import PathTabs from './Nav';
import EditorList from './Edit/EditorList';
import AssignedToList from './Edit/AssignedToList';
import CoursesList from './Courses/List';
import useAppContext from '../../hooks/useAppContext';
import SearchTextField from '../../components/SearchTextField';
import usePathContext from './usePathContext';
import Delete from './Delete';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    minHeight: 'calc(100vh - 120px)'
  }
}));

const Paths = () => {
  const { accessList, profile } = useAppContext();
  const { paths, updatePaths, filteredPaths, filterPaths } = usePathContext();
  const classes = useStyles();
  const [selectedPath, setSelectedPath] = React.useState<PathMetaResponse>();
  const history = useHistory();
  const { pathSlug } = useParams<{ pathSlug: string }>();

  React.useEffect(() => {
    setSelectedPath(paths.find((p) => p.slug === pathSlug));
  }, [paths, pathSlug]);

  const handleChange = async (event: unknown, slug: string) => {
    history.push('/admin/paths/' + slug);
  };

  const handleDelete = () => {
    setSelectedPath(undefined);
    updatePaths();
    history.push('/admin/paths');
  };

  const canEditPath = React.useCallback(
    (pathId?: string) => {
      if (!pathId) return false;
      if (accessList?.paths === Rights.Write) return true;
      if (profile?.pathIdsToEdit?.includes(pathId)) return true;
      return false;
    },
    [accessList?.paths, profile?.pathIdsToEdit]
  );

  return (
    <div className={classes.root}>
      <div
        style={{ display: 'flex', flexDirection: 'column', width: 240, flexShrink: 0 }}
      >
        <Box style={{ margin: '15px 15px 0 15px' }}>
          <SearchTextField callback={filterPaths} />
        </Box>

        {paths && (
          <PathTabs
            pathSlug={pathSlug ?? ''}
            canEdit={accessList?.paths === Rights.Write}
            paths={filteredPaths}
            handleChange={handleChange}
          />
        )}
      </div>
      <div>
        <Typography variant="h3" style={{ padding: 40 }}>
          {pathSlug && paths ? selectedPath?.name : 'Choose a path'}
        </Typography>
        {selectedPath && (
          <EditorList
            editorIds={selectedPath.editorIds ?? []}
            pathId={selectedPath.id}
            updatePaths={updatePaths}
          />
        )}
        {selectedPath && (
          <>
            <Divider style={{ margin: 10 }} variant="inset" />
            <AssignedToList
              userIds={selectedPath.assignedToIds ?? []}
              pathId={selectedPath.id}
              updatePaths={updatePaths}
            />
          </>
        )}
        {paths && (
          <>
            <CoursesList canEdit={canEditPath(selectedPath?.id)} />
            {selectedPath && (
              <div style={{ marginTop: 20, marginRight: 20, textAlign: 'right' }}>
                <Delete pathId={selectedPath.id} onDeleted={handleDelete} />
              </div>
            )}
          </>
        )}
      </div>

      <Route path="/admin/paths//add" component={AddPath} />
      <Route path="/admin/paths/:pathSlug/add">
        <AddCourse
          onSuccess={() => {
            /*TODO*/
          }}
        />
      </Route>
      <Route path="/admin/paths/:pathSlug/editCourse/:courseId" component={EditCourse} />
    </div>
  );
};

export default Paths;
