import React from 'react';
import Form from './Form';
import { useHistory, useParams } from 'react-router-dom';
import { UsersApi, PathsApi, RolesApi } from '../../api/coachApi';
import {
  ProfileDto,
  AddOrUpdateUserRequest,
  ApiResponse,
  PathDto
} from '../../api/coach.generated';
import { toaster } from '../../components/Toaster';
import { CoachDialogWithTitle } from '../../components/CoachDialog';
import { SelectListItem } from '../../components/SelectListItem';

const Edit = () => {
  const history = useHistory();
  let { userId } = useParams<{ userId: string }>();
  const [profile, setProfile] = React.useState<ProfileDto | null>(null);
  const [paths, setPaths] = React.useState<PathDto[] | null>(null);
  const [usersList, setUsersList] = React.useState<SelectListItem[] | null>(null);
  const [roles, setRoles] = React.useState<string[] | null>();

  React.useEffect(() => {
    const fetchData = async () => {
      if (!userId) return;

      const [userRes, pathsRes, usersListRes, rolesRes] = await Promise.all([
        UsersApi.getProfile(userId),
        PathsApi.getAll(),
        UsersApi.getNames(),
        RolesApi.getAll()
      ]);
      setProfile(userRes);
      setPaths(pathsRes);
      setUsersList(usersListRes?.map((u) => ({ id: u.key, label: u.value })) ?? null);
      setRoles(rolesRes);
    };
    fetchData();
  }, [userId]);

  const success = () => {
    history.push('/admin/users');
    toaster.success('User updated');
  };

  const close = () => {
    history.push('/admin/users');
  };

  const loading: boolean = !profile || !paths;

  const endpoint = (model: AddOrUpdateUserRequest): Promise<ApiResponse | null> =>
    UsersApi.put(profile!.id, { ...model });

  return (
    <CoachDialogWithTitle title="Update user" open onClose={close} loading={loading}>
      {profile && usersList && paths && roles && (
        <Form
          profile={profile}
          usersList={usersList}
          allPaths={paths}
          allRoles={roles}
          onSuccess={success}
          endpoint={endpoint}
        />
      )}
    </CoachDialogWithTitle>
  );
};

export default Edit;
