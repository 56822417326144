import React from 'react';
import { PathContext } from './PathProvider';

const usePathContext = () => {
  const context = React.useContext(PathContext);
  if (!context) {
    throw new Error('useAppContext must be used within a PathProvider');
  }
  return context;
};

export default usePathContext;
