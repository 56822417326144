import React from 'react';
import { AccessList, ProfileDto } from '../api/coach.generated';
import { AccountApi } from '../api/coachApi';
import Reviews from '../views/Dashboard/Reviews';

export type AppContextType = {
  profile?: ProfileDto;
  accessList?: AccessList;
  showReviews(courseId: string, editorOpen: boolean): void;
  updateProfile(): void;
  isInRole: (role: string) => boolean;
  notifyOnCourseUpdate(courseId: string): void;
  subscribeOnCourseUpdate(callback: CourseUpdateCallback): void;
  unSubscribeOnCourseUpdate(callback: CourseUpdateCallback): void;
};

export const AppContext = React.createContext<AppContextType>(null!);

type CourseUpdateCallback = {
  (courseId: string): void;
};

const AppProvider: React.FC = ({ children }) => {
  const courseUpdatedCallbacks = React.useRef<CourseUpdateCallback[]>([]);
  const [accessList, setAccessList] = React.useState<AccessList>();
  const [profile, setProfile] = React.useState<ProfileDto>();
  const [courseIdUnderReview, setCourseIdUnderReview] = React.useState<string>();
  const [reviewEditorOpen, setReviewEditorOpen] = React.useState(false);

  const updateProfile = async () => {
    const [accessListRes, profileRes] = await Promise.all([
      AccountApi.getAccessList(),
      AccountApi.getProfile()
    ]);
    accessListRes && setAccessList(accessListRes);
    profileRes && setProfile(profileRes);
  };

  React.useEffect(() => {
    updateProfile();
  }, []);

  const subscribeOnCourseUpdate = (callback: CourseUpdateCallback) => {
    courseUpdatedCallbacks.current.push(callback);
  };

  const unSubscribeOnCourseUpdate = (callback: CourseUpdateCallback) => {
    const index = courseUpdatedCallbacks.current.indexOf(callback);
    if (index > -1) {
      courseUpdatedCallbacks.current.splice(index, 1);
    }
  };

  const notifyOnCourseUpdate = (courseId: string) => {
    courseUpdatedCallbacks.current.forEach((c) => c(courseId));
  };

  const isInRole = (role: string) => !!profile?.roles.includes(role);

  return (
    <AppContext.Provider
      value={{
        profile,
        accessList,
        updateProfile,
        isInRole,
        notifyOnCourseUpdate,
        subscribeOnCourseUpdate,
        unSubscribeOnCourseUpdate,
        showReviews: (courseId: string, editorOpen: boolean) => {
          setReviewEditorOpen(editorOpen);
          setCourseIdUnderReview(courseId);
        }
      }}
    >
      {courseIdUnderReview && (
        <Reviews
          open={true}
          editorOpen={reviewEditorOpen}
          courseId={courseIdUnderReview}
          onClose={() => setCourseIdUnderReview(undefined)}
        />
      )}
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
