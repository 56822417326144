import React from 'react';

/**
 * Helper to keep track whether a component unmounts.
 * Useful for longer running web request as state shouldn't be updated after a component gets unmounted. 
 */
const useIsMounted = () => {
  const [isMounted, setIsMouted] = React.useState(true);
  React.useEffect(() => {
    return () => setIsMouted(false);
  }, []);
  return isMounted;
};
export default useIsMounted;
