import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { GroupDto } from '../../../api/coach.generated';
import Courses from './Courses';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { margin: 10 },
    arrow: {
      position: 'relative',
      top: 6
    },
    header: {
      cursor: 'pointer'
    },
    category: {
      color: '#999',
      fontSize: '.8rem',
      position: 'relative',
      top: -1
    }
  })
);

const Skill = ({ skill, canEdit }: { skill: GroupDto; canEdit: boolean }) => {
  const classes = useStyles();
  const [showCourses, setShowCourses] = React.useState(false);

  return (
    <div className={classes.root}>
      <span className={classes.header} onClick={() => setShowCourses(!showCourses)}>
        <span className={classes.category}>{skill.category} » </span>
        <b>{skill.name}</b> <span> ({skill.count})</span>
      </span>
      {showCourses && <Courses skillId={skill.id} canEdit={canEdit} count={skill.count} />}
    </div>
  );
};

export default Skill;
