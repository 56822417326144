import * as React from 'react';
import { AdminUserStatsByPathDto } from '../../api/coach.generated';
import { styled } from '@material-ui/core';

const StatsContainer = styled('div')({
  marginLeft: '1rem',
  padding: '1rem',
  '&:first-of-type': { margin: 0 },
  '&:hover': { backgroundColor: '#e7e7e7', cursor: 'pointer' }
});

const UserStats = ({
  stats,
  onClick
}: {
  stats: AdminUserStatsByPathDto;
  onClick(event: React.MouseEvent): void;
}) => {
  return (
    <StatsContainer key={stats.pathId} onClick={onClick} title="View path">
      <b>{stats.pathName}</b>
      <br />
      Courses:
      <b>{` ${stats.nrOfUserCoursesFinished}/${stats.totalNrOfCourses} @ 
      ${stats.countPct}%`}</b>
      <br />
      Duration: <b>{stats.durationPct}%</b>
    </StatsContainer>
  );
};

export default UserStats;
