import { toast } from '../components/Toaster';
import { ApiResponse } from './coach.generated';

export const handleErrors = (response: Response) => {
  getErrorHandler(response.status)(response);
};

const getErrorHandler = (statusCode: number) => {
  switch (statusCode) {
    case 401:
      return handle401;
    default:
      return handleDefault;
  }
};

const handle401 = (_: any) => {
  const encodedReturnUri = encodeURIComponent(
    window.location.pathname + window.location.search
  );
  window.location.href = `/login?ReturnUrl=${encodedReturnUri}`;
};

const handleDefault = (error: any) => {
  const apiError = error as ApiResponse;
  toast({
    message: `${apiError.title || 'Something went wrong. Please try again later'}`,
    variant: 'error'
  });
};
