import React, { SyntheticEvent } from 'react';
import clsx from 'clsx';
import { amber, green } from '@material-ui/core/colors';
import {
  Warning as WarningIcon,
  Close as CloseIcon,
  Info as InfoIcon,
  Error as ErrorIcon,
  CheckCircle as CheckCircleIcon
} from '@material-ui/icons';
import {
  makeStyles,
  Theme,
  IconButton,
  Snackbar,
  Grow,
  SnackbarContent
} from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';

function GrowTransition(props: TransitionProps) {
  return <Grow {...props} />;
}

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon
};

const useStyles = makeStyles((theme: Theme) => ({
  success: {
    backgroundColor: green[600]
  },
  error: {
    backgroundColor: theme.palette.error.dark
  },
  info: {
    backgroundColor: theme.palette.primary.main
  },
  warning: {
    backgroundColor: amber[700]
  },
  icon: {
    fontSize: 20
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1)
  },
  message: {
    display: 'flex',
    alignItems: 'center'
  }
}));

interface ToasterState {
  open: boolean;
  className?: string;
  message: React.ReactNode | string;
  variant: keyof typeof variantIcon;
}

let setState: Function;
let state: ToasterState;

export type ToasterProps = Omit<ToasterState, 'open'>;

// TODO: phase that out and use toaster object
export const toast = (props: ToasterProps) => {
  setState({ open: true, ...props });
};

const handleClose = (event?: SyntheticEvent, reason?: string) => {
  if (reason === 'clickaway') {
    return;
  }

  setState({
    ...state,
    open: false
  });
};

export const ToastContainer = () => {
  [state, setState] = React.useState<ToasterState>({
    open: false,
    variant: 'success',
    message: ''
  });
  const classes = useStyles();
  const Icon = variantIcon[state.variant];

  return (
    <Snackbar
      TransitionComponent={GrowTransition}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center'
      }}
      open={state.open}
      autoHideDuration={3000}
      onClose={handleClose}
    >
      <SnackbarContent
        className={clsx(classes[state.variant], state.className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {state.message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>
        ]}
      />
    </Snackbar>
  );
};

export const toaster = {
  success(message: string) {
    toast({ message, variant: 'success' });
  },

  error(message: string) {
    toast({ message, variant: 'error' });
  },

  info(message: string) {
    toast({ message, variant: 'info' });
  }
};
