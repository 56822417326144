import * as React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { green, grey, yellow } from '@material-ui/core/colors';
import Radio, { RadioProps } from '@material-ui/core/Radio';
import { FormControl, RadioGroup, FormControlLabel, Button } from '@material-ui/core';
import {
  PlaylistAdd as PlaylistAddIcon,
  DeleteOutline as DeleteOutlineIcon,
  Star as StarIcon
} from '@material-ui/icons';
import { UserCourseDto, CourseStatus } from '../../../api/coach.generated';
import { UsersApi } from '../../../api/coachApi';
import { toast } from '../../../components/Toaster';
import useAppContext from '../../../hooks/useAppContext';
import useLazyRequest from '../../../hooks/useLazyRequest';

const GreyRadio = withStyles({
  root: {
    color: grey[400],
    '&$checked': {
      color: grey[600]
    }
  },
  checked: {}
})((props: RadioProps) => <Radio color="default" {...props} />);

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600]
    }
  },
  checked: {}
})((props: RadioProps) => <Radio color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  button: {
    marginLeft: theme.spacing(3),
    alignItems: 'end'
  }
}));

const Actions = ({
  course,
  additonalActions,
  onCourseUpdated,
  onGoalRemoved
}: {
  course: UserCourseDto;
  additonalActions?: React.ReactNode;
  onCourseUpdated: (course: UserCourseDto) => void;
  onGoalRemoved(course: UserCourseDto): void;
}) => {
  //const [courseStatus, setCourseStatus] = React.useState(course.status);
  const [loading, setLoading] = React.useState(false);
  const classes = useStyles();

  const transitionCourseRequest = useLazyRequest((profileId, courseId, newStatus) =>
    UsersApi.transitionCourse(profileId, courseId, newStatus)
  );

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    const newStatus = (event.target as HTMLInputElement).value as CourseStatus;
    const res = await transitionCourseRequest.execute(
      profile!.id,
      course.id,
      newStatus
    );

    if (!res || transitionCourseRequest.errorMessage) return;
    //setCourseStatus(newStatus)

    const newCourse: UserCourseDto = {
      ...course,
      status: res.status ?? CourseStatus.Todo,
      startedOn: res.startedOn,
      completedOn: res.completedOn
    };
    onCourseUpdated(newCourse);
    if (res?.completedOn) showReviews(course.id, true);

    setLoading(false);
  };

  const addToGoals = async (e: React.MouseEvent, course: UserCourseDto) => {
    e.stopPropagation();
    setLoading(true);
    const res = await UsersApi.addGoal(profile!.id, course.id);
    setLoading(false);
    if (res) {
      toast({ message: 'Goal added', variant: 'success' });
      const newCourse: UserCourseDto = {
        ...course,
        goalId: res.id,
        goalCreatedOn: res.createdOn
      };
      onCourseUpdated(newCourse);
    }
  };

  const removeGoal = async (e: React.MouseEvent, course: UserCourseDto) => {
    e.stopPropagation();
    if (!course.goalId) return;

    setLoading(true);
    const res = await UsersApi.removeGoal(profile!.id, course.goalId);
    setLoading(false);
    if (res) {
      toast({ message: 'Goal removed', variant: 'success' });
      onGoalRemoved(course);
    }
  };

  const formControlClickHandler = (e: React.MouseEvent<HTMLLabelElement>) => {
    e.stopPropagation();
  };

  const { profile, showReviews } = useAppContext();

  return (
    <div className={classes.wrapper}>
      <FormControl component="fieldset">
        <RadioGroup
          aria-label="position"
          name="position"
          value={course.status ?? CourseStatus.Todo}
          onChange={handleChange}
          row
        >
          <FormControlLabel
            value={CourseStatus.Todo}
            control={<GreyRadio />}
            label="Todo"
            labelPlacement="start"
            disabled={loading}
            onClick={formControlClickHandler}
          />
          <FormControlLabel
            value={CourseStatus.InProgress}
            control={<Radio color="primary" />}
            label="In progress"
            labelPlacement="start"
            disabled={loading}
            onClick={formControlClickHandler}
          />
          <FormControlLabel
            value={CourseStatus.Done}
            control={<GreenRadio />}
            label="Done"
            labelPlacement="start"
            disabled={loading}
            onClick={formControlClickHandler}
          />
        </RadioGroup>
      </FormControl>
      {course.completedOn && (
        <Button
          onClick={(e) => {
            e.stopPropagation();
            showReviews(course.id, true);
          }}
          size="small"
          variant="text"
          className={classes.button}
          endIcon={<StarIcon style={{ color: yellow[800] }} />}
        >
          Leave a review
        </Button>
      )}

      {!course.goalId && !course.completedOn && (
        <Button
          disabled={loading}
          onClick={(e) => addToGoals(e, course)}
          variant="text"
          color="default"
          className={classes.button}
          endIcon={<PlaylistAddIcon />}
          size="small"
        >
          Add goal
        </Button>
      )}
      {course.goalId && (
        <Button
          disabled={loading}
          onClick={(e) => removeGoal(e, course)}
          variant="text"
          color="default"
          className={classes.button}
          endIcon={<DeleteOutlineIcon />}
          size="small"
        >
          Remove goal
        </Button>
      )}
      {additonalActions}
    </div>
  );
};

export default Actions;
