import React from 'react';
import useStyles from './useStyles';
import { Button, Grid, Typography } from '@material-ui/core';
import { FormHandler } from '../../helpers/FormHandler';
import { useFormHandler } from '../../helpers';
import { SetNewPasswordRequest } from '../../api/coach.generated';
import { AuthApi } from '../../api/coachApi';

const SetNewPassword = () => {
  const classes = useStyles();
  const [submitEnabled, setSubmitEnabled] = React.useState(true);

  const searchParams = new URLSearchParams(window.location.search);
  const token = searchParams.get('token')!;
  const email = searchParams.get('email')!;

  const formHandler: FormHandler<SetNewPasswordRequest> = useFormHandler({
    model: {} as SetNewPasswordRequest,
    endpoint: () =>
      AuthApi.setNewPassword({
        email: email,
        token: token,
        password: formHandler.state.model.password,
        confirmPassword: formHandler.state.model.confirmPassword
      }),
    onSuccess: (response) => {}
  });

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setSubmitEnabled(false);
    await formHandler.execute();
    !formHandler.state.success && setSubmitEnabled(true);
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography className={classes.quoteText} variant="h1">
                Knowledge awaits you!
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleSubmit}
                hidden={formHandler.state.success}
              >
                <Typography className={classes.title} variant="h2">
                  Set new password
                </Typography>
                {formHandler.GenericError()}
                {formHandler.FormField({
                  propName: 'password',
                  label: 'Password',
                  type: 'password'
                })}
                {formHandler.FormField({
                  propName: 'confirmPassword',
                  label: 'Confirm Password',
                  type: 'password'
                })}
                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={!submitEnabled || !formHandler.state.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Submit
                </Button>
              </form>
              <h4 className={classes.resetSent} hidden={!formHandler.state.success}>
                Password has been changed, <a href="/login">login</a>
              </h4>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SetNewPassword;
