import * as React from 'react';
import {
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';
import Autocomplete, {
  AutocompleteChangeReason,
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import { useStyles as useFormStyles } from '../helpers/FormField';
import { SkillDto } from '../api/coach.generated';
import { titleCase } from '../helpers/titleCase';
import { SkillsApi } from '../api/coachApi';

export interface SkillOptionType {
  inputValue?: string;
  category: string;
  name: string;
}

const filter = createFilterOptions<SkillOptionType>();

export const renderOptionLabel = (option: SkillOptionType) =>
  option
    ? option.category
      ? `${titleCase(option.category)}: ${titleCase(option.name)}`
      : `${titleCase(option.name)}`
    : '';

const Skills = ({
  value,
  setValue,
  disabled,
  error
}: {
  value?: SkillOptionType;
  setValue(option: SkillOptionType | null): void;
  disabled?: boolean;
  error?: string;
}) => {
  const formClasses = useFormStyles();

  const [skillList, setSkillList] = React.useState<SkillDto[]>([]);
  const [dialogValue, setDialogValue] = React.useState({
    category: '',
    name: ''
  });
  const [open, toggleOpen] = React.useState(false);

  React.useEffect(() => {
    const getSkillList = async () => {
      const skills = await SkillsApi.getAll();
      skills && setSkillList(skills);
    };
    getSkillList();
  }, []);

  const handleClose = () => {
    setDialogValue({
      category: '',
      name: ''
    });
    toggleOpen(false);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    setValue({
      category: titleCase(dialogValue.category),
      name: titleCase(dialogValue.name)
    });
    handleClose();
  };

  const handleChange = (
    event: any,
    newValue: string | SkillOptionType | null,
    reason: AutocompleteChangeReason
  ) => {
    if (typeof newValue === 'string') {
      // timeout to avoid instant validation of the dialog's form.
      setTimeout(() => {
        toggleOpen(true);
        setDialogValue({
          category: '',
          name: titleCase(newValue)
        });
      });
      return;
    }

    if (newValue && newValue.inputValue) {
      toggleOpen(true);
      setDialogValue({
        category: '',
        name: titleCase(newValue.inputValue)
      });
      return;
    }
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <Autocomplete
        disabled={disabled}
        value={value}
        options={(skillList as SkillOptionType[]).sort(
          (a, b) => -b.category.localeCompare(a.category) || -b.name.localeCompare(a.name)
        )}
        groupBy={(option) => option.category}
        onBlur={(e) => {
          const inputValue = (e as React.FocusEvent<HTMLInputElement>).target.value;
          const categoryAndName = inputValue.split(':');
          if (categoryAndName.length > 1)
            setValue({
              category: categoryAndName[0].trim(),
              name: categoryAndName[1].trim()
            });
          else setValue(null);
        }}
        onChange={handleChange}
        filterOptions={(options, params) => {
          const filtered = filter(options, params) as SkillOptionType[];

          if (params.inputValue !== '') {
            filtered.push({
              inputValue: titleCase(params.inputValue),
              name: `+ Add "${titleCase(params.inputValue)}"`,
              category: ''
            });
          }

          return filtered;
        }}
        id="skill"
        //groupBy={(option) => option.category}
        getOptionLabel={(option) => {
          // e.g value selected with enter, right from the input
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return renderOptionLabel(option);
        }}
        renderOption={renderOptionLabel}
        freeSolo
        renderInput={(params) => (
          <TextField
            {...params}
            fullWidth
            label="Skill *"
            variant="outlined"
            className={formClasses.fieldGroup}
            helperText={error}
            error={!!error}
          />
        )}
      />
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form onSubmit={handleSubmit}>
          <DialogTitle id="form-dialog-title">Add a skill</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              required
              value={dialogValue.category}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  category: titleCase(event.target.value)
                })
              }
              label="category"
              type="text"
            />
            <TextField
              margin="dense"
              required
              value={dialogValue.name}
              onChange={(event) =>
                setDialogValue({
                  ...dialogValue,
                  name: titleCase(event.target.value)
                })
              }
              label="name"
              type="text"
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" color="primary">
              Add
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
};

export default Skills;
