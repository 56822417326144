import React from 'react';
import Search from './Search';
import New from './New';

const Add = ({ onSuccess }: { onSuccess(): void }) => {
  const [addNew, setAddNew] = React.useState(false);
  const [searchTerm, setSearchTerm] = React.useState('');

  return (
    <>
      {addNew ? (
        <New term={searchTerm} onSuccess={onSuccess} />
      ) : (
        <Search
          setSearchTerm={setSearchTerm}
          onClickAddNew={() => setAddNew(true)}
          onSuccess={onSuccess}
        />
      )}
    </>
  );
};

export default Add;
