import { ProblemDetails, ValidationProblemDetails } from './../api/coach.generated';
import React from 'react';
import { GenericError, FormField, InputTypes, Input } from './FormField';
import { SelectListItem } from '../components/SelectListItem';

type PropBoolDic<TModel> = Record<keyof TModel, boolean>;

export interface FormState<TModel> {
  isValid: boolean;
  inProgress: boolean;
  success: boolean;
  model: TModel;
  genericError: string;
  touched: PropBoolDic<TModel>;
  errors: { [key: string]: string[]; };
}

export interface FormHandlerProps<TModel, TResponse> {
  model: TModel;
  endpoint(...args: unknown[]): Promise<TResponse | null>;
  onSuccess?: (response: TResponse) => void;
}

export type FormFieldProps<TModel> = {
  propName: Extract<keyof TModel, string>;
  label?: string;
  type?: InputTypes;
  className?: string;
  style?: React.CSSProperties;
  required?: boolean;
  disabled?: boolean;
  list?: SelectListItem[];
};

export type FormHandler<TModel> = {
  state: FormState<TModel>;
  handleChange(input: Input): void;
  /** Update single field */
  updateProp(propName: Extract<keyof TModel, string>, propValue: unknown): void;
  /** Update the entire model */
  updateModel(model: TModel): void;
  /** Send request to the server */
  execute(): Promise<void>;
  propError(propName: Extract<keyof TModel, string>): string;
  propTouched(propName: Extract<keyof TModel, string>): boolean;
  GenericError(): JSX.Element;
  FormField(props: FormFieldProps<TModel>): JSX.Element;
};

export const useFormHandler = <TModel, TResponse>({
  model,
  endpoint,
  onSuccess
}: FormHandlerProps<TModel, TResponse>): FormHandler<TModel> => {
  const defaultModel = {
    isValid: true,
    inProgress: false,
    success: false,
    model,
    genericError: '',
    touched: {} as PropBoolDic<TModel>,
    errors: {}
  };
  const [state, setState] = React.useState<FormState<TModel>>(defaultModel);

  const execute = async (): Promise<void> => {
    setInProgress(true);
    let response: TResponse | ProblemDetails | ValidationProblemDetails | null = await endpoint();
    setInProgress(false);
    response && handleResponse(response);
  };

  const propError = (propName: Extract<keyof TModel, string>) => state.errors[propName].join(', ');
  const propTouched = (propName: Extract<keyof TModel, string>) =>
    state.touched[propName];

  const handleResponse = (response: TResponse | ValidationProblemDetails): void => {
    if (!response) return;

    let successResponse: TResponse | null = null; //TODO: rename TResponse to TSuccessResponse?
    let isSuccessful = isResponseSuccessful(response);
    let errors: { [key: string]: string[] } = {};
    let genericError = '';

    if (isSuccessful) {
      successResponse = response as TResponse;
    }

    if (typeof response === "object" && "errors" in response && response.errors) {
      errors = response.errors ?? {};
      genericError = response.errors['']?.join(', ') ?? '';
    }

    setState({
      ...state,
      success: isSuccessful,
      genericError: genericError,
      errors: errors,
      touched: {} as PropBoolDic<TModel>
    });

    successResponse && onSuccess && onSuccess(successResponse);
  };

  const isResponseSuccessful = (response: TResponse | ValidationProblemDetails) => {
    if (!response || typeof response !== "object" || !("status" in response))
      return true;

    return (response.status ?? 500) < 400;
  }

  const handleChange = (input: Input) => {
    //complicated structure, e.g. checklist
    if (input.name.includes('__')) {
      const [propName, listItemId] = input.name.split('__');
      const currentList: string[] = (state.model as any)[propName];
      const newList = input.checked
        ? [...currentList, listItemId]
        : currentList.filter((i) => i !== listItemId);
      updateProp(propName, newList);
      return;
    }

    updateProp(input.name, input.type === 'checkbox' ? input.checked : input.value);
  };

  const setInProgress = (bool: boolean) => {
    setState({ ...state, inProgress: bool });
  };

  const updateProp = (propName: string, propValue: unknown) => {
    setState({
      ...state,
      isValid: true,
      model: {
        ...state.model,
        [propName]: propValue
      },
      touched: {
        ...state.touched,
        [propName]: true
      }
    });
  };

  const updateModel = (model: TModel) => {
    setState({
      ...state,
      isValid: true,
      model: {
        ...model
      }
    });
  };

  return {
    state,
    execute,
    handleChange,
    updateProp,
    updateModel,
    propError,
    propTouched,
    GenericError: (): JSX.Element => GenericError(state.genericError),
    FormField: (props: FormFieldProps<TModel>): JSX.Element =>
      FormField({
        label: props.label,
        propName: props.propName,
        onChange: handleChange,
        required: props.required,
        disabled: props.disabled,
        style: props.style,
        type: props.type,
        value: (state.model && state.model[props.propName]) || '',
        className: props.className,
        error: state.errors && state.errors[props.propName]?.join(', '),
        model: state.model,
        list: props.list
      })
  };
};
