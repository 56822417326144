import React from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { PathMetaResponse } from '../../api/coach.generated';
import { PathsApi } from '../../api/coachApi';

export type PathContextType = {
  paths: PathMetaResponse[];
  filteredPaths: PathMetaResponse[];
  dragEndCallbacks: React.MutableRefObject<{ [key: string]: any }>;
  updatePaths(): void;
  filterPaths(val: string): void;
};

export const PathContext = React.createContext<PathContextType>(null!);

const PathProvider: React.FC = ({ children }) => {
  const dragEndCallbacks = React.useRef<{ [key: string]: any }>({});
  const [paths, setPaths] = React.useState<PathMetaResponse[]>([]);
  const [filteredPaths, setFilteredPaths] = React.useState<PathMetaResponse[]>([]);

  React.useEffect(() => {
    const fetchPaths = async () => {
      const pathsRes = await PathsApi.getMeta();
      if (pathsRes) {
        setPaths(pathsRes);
        setFilteredPaths(pathsRes);
      }
    };
    fetchPaths();
  }, []);

  const handleDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) return;

    Object.keys(dragEndCallbacks.current).forEach((k) => {
      dragEndCallbacks.current[k](result);
    });
  };

  const updatePaths = async () => {
    const pathsRes = await PathsApi.getMeta();
    if (pathsRes) {
      setPaths(pathsRes);
      setFilteredPaths(pathsRes);
    }
  };

  const filterPaths = (val: string) => {
    if (!val) {
      setFilteredPaths(paths);
      return;
    }

    setFilteredPaths(
      paths?.filter((u) => u.name.toLowerCase().startsWith(val.toLowerCase()))
    );
  };

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <PathContext.Provider
        value={{
          paths,
          filteredPaths,
          dragEndCallbacks,
          updatePaths,
          filterPaths
        }}
      >
        {children}
      </PathContext.Provider>
    </DragDropContext>
  );
};

export default PathProvider;
