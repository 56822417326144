import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Button, colors } from '@material-ui/core';
import { SitemapItem } from './sitemap';

const useStyles = makeStyles(theme => ({
  root: {},
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    //TODO
    // color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  active: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    '& $icon': {
      color: theme.palette.primary.main
    }
  }
}));

const CustomRouterLink = React.forwardRef<HTMLDivElement>((props: any, ref) => (
  <div ref={ref} style={{ flexGrow: 1 }}>
    <NavLink {...props} />
  </div>
));

const SidebarNav = ({
  pages,
  className
}: {
  pages: SitemapItem[];
  className: string;
}) => {
  const classes = useStyles();

  const button = (page: SitemapItem) =>
    page.href ? (
      <Button
        activeClassName={classes.active}
        className={classes.button}
        component={CustomRouterLink}
        {...({ to: page.href } as any)}
      >
        <div className={classes.icon}>{page.icon}</div>
        {page.title}
      </Button>
    ) : (
      <Button className={classes.button} disabled title="TODO">
        <div className={classes.icon}>{page.icon}</div>
        {page.title}
      </Button>
    );

  return (
    <List className={clsx(classes.root, className)}>
      {pages.map(page => (
        <ListItem className={classes.item} disableGutters key={page.title}>
          {button(page)}
        </ListItem>
      ))}
    </List>
  );
};

export default SidebarNav;
