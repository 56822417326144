import { Box, Chip, TextField } from '@material-ui/core';
import { UsersApi, PathsApi } from '../../../api/coachApi';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import { Rights } from '../../../api/coach.generated';
import CircularLoadingButton from '../../../components/CircularLoadingButton';
import { Add as PlusOneIcon } from '@material-ui/icons';
import { toaster } from '../../../components/Toaster';
import useAppContext from '../../../hooks/useAppContext';
import Roles from '../../../helpers/Roles';
import { SelectListItem } from '../../../components/SelectListItem';
import useIsMounted from '../../../hooks/useIsMounted';

const EditorList = ({
  editorIds,
  pathId,
  updatePaths
}: {
  editorIds: string[];
  pathId: string;
  updatePaths: () => void;
}) => {
  const isMounted = useIsMounted();
  const { accessList, profile, isInRole } = useAppContext();
  const [users, setUsers] = React.useState<SelectListItem[]>([]);
  const [selected, setSelected] = React.useState<SelectListItem | null>(null);
  const [isSaving, setIsSaving] = React.useState(false);

  const canRender = React.useCallback((): boolean => {
    if (accessList?.paths === Rights.Write) return true;

    if (isInRole(Roles.TeamLead) && profile?.pathIdsToEdit?.includes(pathId)) return true;

    return false;
  }, [accessList?.paths, isInRole, pathId, profile?.pathIdsToEdit]);

  React.useEffect(() => {
    if (!canRender()) return;

    const fetchUsers = async () => {
      const res = await UsersApi.getNames();
      res && isMounted && setUsers(res.map((u) => ({ id: u.key, label: u.value })));
    };

    fetchUsers();
  }, [canRender, editorIds, isMounted]);

  const add = async () => {
    if (!selected) return;

    setIsSaving(true);
    await PathsApi.addEditor(pathId, selected.id);
    toaster.success('Editor added');
    setIsSaving(false);
    updatePaths();
  };

  const remove = async (userId: string) => {
    if (!window.confirm('Sure?')) return;

    setIsSaving(true);
    await PathsApi.removeEditor(pathId, userId);
    toaster.success('Editor removed');
    setIsSaving(false);
    updatePaths();
  };

  if (!canRender()) return null;

  const editors = users?.filter((u) => editorIds.includes(u.id));

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box display="flex" mx={2} width={130} flexShrink="0" textAlign="right">
        Editors ({editors?.length ?? 0}):
      </Box>
      {editors?.map((u) => (
        <Chip
          style={{ margin: '0 2px 0' }}
          key={u.id}
          label={u.label}
          onDelete={() => remove(u.id)}
          disabled={isSaving}
        />
      ))}
      <Box display="flex" flexShrink="0" style={{ width: 220, marginLeft: 10 }}>
        <Autocomplete
          disabled={isSaving}
          value={selected}
          getOptionSelected={(o, v) => o.id === v.id}
          options={users}
          onChange={(event, value, reason, details) => setSelected(value)}
          getOptionLabel={(option) => option.label}
          renderOption={(option) => option.label}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" size="small" />
          )}
        />
      </Box>
      <CircularLoadingButton
        onClick={add}
        loading={isSaving}
        size="small"
        icon={<PlusOneIcon />}
      />
    </Box>
  );
};

export default EditorList;
