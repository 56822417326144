import { toast } from '../components/Toaster';
import { ValidationProblemDetails } from './coach.generated';

const flattenErrors = (
  errors: { [key: string]: string[] } | undefined
): string[] | undefined => {
  if (!errors) return undefined;
  const flatErrors: string[] = [];
  Object.keys(errors).forEach((key) => {
    flatErrors.push(errors[key].join(','));
  });

  return flatErrors;
};

export const httpMiddleware = {
  fetch: async (url: string, options: RequestInit): Promise<Response> => {
    options.credentials = 'include';
    let response = null;
    try {
      response = await fetch(url, options);
    } catch (error) {}

    if (response!.status === 401) redirectToLogin();
    else if (!response || !response.ok) {
      const body = await response!.text();
      let responseObj: ValidationProblemDetails | null = null;
      if (body.startsWith('{')) {
        responseObj = JSON.parse(body) as ValidationProblemDetails;
      }

      const message =
        flattenErrors(responseObj?.errors)?.join('\n') ||
        responseObj?.title ||
        'Oops, something went wrong';

      toast({
        variant: 'error',
        message: message
      });
      // Override status code to prevent Nswag from raising an exception
      return body.startsWith('{') // JSON format?
        ? new Response(body, { status: 200 })
        : new Response(null, { status: 204 });
    }

    return response!;
  }
};

const redirectToLogin = () => {
  const loginPath = '/login';
  const encodedReturnUri = encodeURIComponent(
    window.location.pathname + window.location.search
  );
  window.location.href = `${loginPath}?ReturnUrl=${encodedReturnUri}`;
};
