import 'date-fns';
import React from 'react';
import { Box } from '@material-ui/core';
import { CourseGoalDto, UserCourseDto } from '../../../api/coach.generated';
import Course from '../Pathways/Course';
import Actions from '../Pathways/Actions';
import useDashboardContext from '../useDashboardContext';
import DueDate from '../../../components/Goal/DueDate';
import useAppContext from '../../../hooks/useAppContext';

const Goal = ({ goal }: { goal: CourseGoalDto }) => {
  const { notifyOnCourseUpdate } = useAppContext();
  const { goalsRequest, pathsRequest } = useDashboardContext();

  const onCourseUpdated = (course: UserCourseDto) => {
    goalsRequest.execute();
    pathsRequest.execute();
  };
  const onGoalRemoved = (course: UserCourseDto) => {
    goalsRequest.execute();
    notifyOnCourseUpdate(course.id);
  };

  return (
    <Box key={goal.id} display="flex" my={2}>
      <Box mr={1}>
        <DueDate goalId={goal.id} dueDate={goal.dueDate} />
      </Box>
      <Box style={{ marginTop: 3 }}>
        <Course
          course={goal.course!}
          actions={
            <Actions
              course={goal.course!}
              onCourseUpdated={onCourseUpdated}
              onGoalRemoved={onGoalRemoved}
            />
          }
        />
      </Box>
    </Box>
  );
};

export default Goal;
