import { Box, Chip, IconButton, TextField, Tooltip } from '@material-ui/core';
import { UsersApi, PathsApi } from '../../../api/coachApi';
import { Autocomplete } from '@material-ui/lab';
import React from 'react';
import CircularLoadingButton from '../../../components/CircularLoadingButton';
import { Add as PlusOneIcon } from '@material-ui/icons';
import {
  KeyboardArrowRight as KeyboardArrowRightIcon,
  KeyboardArrowLeft as KeyboardArrowLeftIcon
} from '@material-ui/icons';
import { toaster } from '../../../components/Toaster';
import useAppContext from '../../../hooks/useAppContext';
import Roles from '../../../helpers/Roles';
import { SelectListItem } from '../../../components/SelectListItem';

const AssignedToList = ({
  userIds,
  pathId,
  updatePaths
}: {
  userIds: string[];
  pathId: string;
  updatePaths: () => void;
}) => {
  const [users, setUsers] = React.useState<SelectListItem[]>([]);
  const [assignees, setAssignees] = React.useState<SelectListItem[]>([]);
  const [selected, setSelected] = React.useState<SelectListItem | null>(null);
  const [isSaving, setIsSaving] = React.useState(false);
  const { isInRole } = useAppContext();
  const [truncate, setTruncate] = React.useState(true);

  const canRender = React.useCallback((): boolean => {
    if (isInRole(Roles.Admin)) return true;
    if (isInRole(Roles.TeamLead)) return true;

    return false;
  }, [isInRole]);

  React.useEffect(() => {
    if (!canRender()) return;

    let isMounted = true;
    const fetchUsers = async () => {
      const usersRes = await UsersApi.getNames();
      if (!usersRes || !isMounted) return;
      const selectList: SelectListItem[] = usersRes.map((u) => ({
        id: u.key,
        label: u.value
      }));
      setUsers(selectList);
      setAssignees(selectList.filter((u) => userIds.includes(u.id)) ?? []);
    };

    fetchUsers();
    return () => {
      isMounted = false;
    };
  }, [canRender, userIds]);

  const add = async () => {
    if (!selected) return;

    setIsSaving(true);
    await PathsApi.addAssignee(pathId, selected.id);
    toaster.success('User added');
    setIsSaving(false);
    updatePaths();
  };

  const remove = async (userId: string) => {
    if (!window.confirm('Sure?')) return;

    setIsSaving(true);
    await PathsApi.removeAssignee(pathId, userId);
    toaster.success('User removed');
    setIsSaving(false);
    updatePaths();
  };

  if (!canRender()) return null;

  const truncateIfNeeded = <T,>(arr: T[], predicate: boolean) =>
    predicate ? arr?.slice(0, 3) : arr;

  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box display="flex" mx={2} width={130} flexShrink="0" textAlign="right">
        Assigned to ({assignees?.length ?? 0}):
      </Box>
      <Box>
        {truncateIfNeeded(assignees, truncate === true).map((u) => (
          <Chip
            style={{ margin: 2 }}
            key={u.id}
            label={u.label}
            onDelete={() => remove(u.id)}
            disabled={isSaving}
          />
        ))}
      </Box>

      {(assignees.length <= 3 || !truncate) && (
        <>
          <Box display="flex" flexShrink="0" style={{ width: 220, marginLeft: 10 }}>
            <Autocomplete
              disabled={isSaving}
              value={selected}
              getOptionSelected={(o, v) => o.id === v.id}
              options={users}
              onChange={(event, value, reason, details) => setSelected(value)}
              getOptionLabel={(option) => option.label}
              renderOption={(option) => option.label}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" size="small" />
              )}
            />
          </Box>
          <CircularLoadingButton
            onClick={add}
            loading={isSaving}
            size="small"
            icon={<PlusOneIcon />}
          />
        </>
      )}
      {assignees?.length > 3 && (
        <Tooltip title={truncate ? 'More' : 'Less'}>
          <IconButton onClick={() => setTruncate((val) => !val)} aria-label="more">
            {truncate ? <KeyboardArrowRightIcon /> : <KeyboardArrowLeftIcon />}
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};

export default AssignedToList;
