import React from 'react';
import numeral from 'numeral';
import { AccessTime as TimeIcon, Star as StarIcon } from '@material-ui/icons';
import { yellow, grey } from '@material-ui/core/colors';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import useAppContext from '../hooks/useAppContext';

const useStyles = makeStyles((theme) => ({
  label: {
    color: '#999'
  },
  courseName: {
    color: '#263238',
    fontWeight: 600
  },
  reviewLink: {
    '&:hover .icon': {
      color: grey[400]
    }
  },
  actions: {
    marginTop: theme.spacing(1)
  },
  starIcon: {
    height: 14,
    position: 'relative',
    top: 3,
    marginRight: -5,
    color: yellow[800]
  },
  disabled: {
    color: grey[400]
  },
  timeIcon: {
    height: 14,
    position: 'relative',
    top: 3,
    marginRight: -5
  }
}));

export interface ICourseDetails {
  id: string;
  name: string;
  rating: number;
  skill?: string;
  skillCategory?: string;
  rank?: number;
  durationSeconds?: number;
}

const CourseDetails = ({
  course,
  hideSkillAndRank
}: {
  course: ICourseDetails;
  hideSkillAndRank?: boolean;
}): JSX.Element => {
  var { showReviews } = useAppContext();
  const classes = useStyles();

  const titleMeta = () => {
    if (hideSkillAndRank) return null;
    return (
      <>
        {course.rank ? `${course.rank}.` : null} {course.skillCategory} » {course.skill} »
      </>
    );
  };

  return (
    <span className={classes.label}>
      {titleMeta()}
      <span className={classes.courseName}> {course.name} </span>
      {!!course.durationSeconds && (
        <>
          <TimeIcon className={classes.timeIcon} />{' '}
          {numeral(course.durationSeconds).format('00:00:00')}
        </>
      )}
      <span
        className={classes.reviewLink}
        onClick={(e) => {
          e.stopPropagation();
          showReviews(course.id, false);
        }}
      >
        <StarIcon
          className={clsx(classes.starIcon, 'icon', {
            [classes.disabled]: !course.rating
          })}
        />{' '}
        {course.rating.toFixed(1)}
      </span>
    </span>
  );
};

export default CourseDetails;
