import React from 'react';
import { DeleteOutline as DeleteOutlineIcon } from '@material-ui/icons';
import { red } from '@material-ui/core/colors';
import {
  Button,
  CircularProgress,
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      color: theme.palette.getContrastText(red[500]),
      backgroundColor: red[500],
      '&:hover': {
        backgroundColor: red[700]
      }
    }
  })
);

const ButtonDelete = ({
  isLoading,
  onClick
}: {
  isLoading?: boolean;
  onClick?(): void;
}) => {
  const classes = useStyles();

  return (
    <Button
      variant="contained"
      className={classes.button}
      endIcon={
        isLoading ? (
          <CircularProgress size={16} color="secondary" />
        ) : (
          <DeleteOutlineIcon />
        )
      }
      onClick={onClick}
      disabled={isLoading}
    >
      Delete
    </Button>
  );
};

export default ButtonDelete;
