import * as React from 'react';
import {
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  IconButton
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import onKeyEnter from '../helpers/onKeyEnter';

const SearchBar = ({
  value,
  onSearch,
  label = 'Search',
  placeholder
}: {
  value?: string;
  onSearch(value: string): void;
  label?: string;
  placeholder?: string;
}) => {
  const [keyword, setKeyword] = React.useState('');

  const search = () => onSearch(keyword);

  return (
    <FormControl style={{ width: '30rem' }} variant="outlined">
      <InputLabel>{label}</InputLabel>
      <OutlinedInput
        autoFocus
        type="text"
        value={value}
        onChange={(e) => setKeyword(e.target.value)}
        onKeyPress={(e) => onKeyEnter(e, search)}
        placeholder={placeholder}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={search} edge="end">
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
        labelWidth={70}
      />
    </FormControl>
  );
};

export default SearchBar;
