import * as React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Tooltip, Fab, Chip } from '@material-ui/core';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  useParams
} from 'react-router-dom';
import {
  Add as AddIcon,
  Face as FaceIcon,
  School as SchoolIcon,
  List as ListIcon,
  Lock as LockIcon
} from '@material-ui/icons';
import { PathMetaResponse } from '../../api/coach.generated';
import useAppContext from '../../hooks/useAppContext';

const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => {
  return <RouterLink innerRef={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  tabs: {
    height: 'calc(100vh - 160px)',
    width: '100%',
    borderRight: `1px solid ${theme.palette.divider}`
  },
  tabLabel: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5)
    }
  }
}));

const PathTabs = ({
  paths,
  canEdit,
  handleChange
}: {
  pathSlug: string;
  canEdit: boolean;
  paths: PathMetaResponse[];
  handleChange(event: unknown, slug: string): void;
}) => {
  const { profile } = useAppContext();
  const classes = useStyles();
  const { pathSlug } = useParams<{ pathSlug: string }>();

  const hasCurrentPath = !!paths.filter((p) => p.slug === pathSlug).length;

  return (
    <div className={classes.root}>
      <Tabs
        orientation="vertical"
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="scrollable"
        className={classes.tabs}
        value={hasCurrentPath ? pathSlug : false}
      >
        {paths?.map((p, idx) => (
          <Tab
            key={p.id}
            label={
              <>
                {p.name}
                <div className={classes.tabLabel}>
                  {!canEdit && !profile?.pathIdsToEdit?.includes(p.id) && (
                    <Tooltip title="Read only">
                      <LockIcon />
                    </Tooltip>
                  )}
                  <Tooltip title="Nr of courses">
                    <Chip size="small" icon={<ListIcon />} label={p.nrOfCourses} />
                  </Tooltip>
                  <Tooltip title="Assignees">
                    <Chip
                      size="small"
                      icon={<FaceIcon />}
                      label={p.assignedToIds?.length}
                    />
                  </Tooltip>
                  <Tooltip title="Nr of editors">
                    <Chip
                      size="small"
                      icon={<SchoolIcon />}
                      label={p.editorIds?.length}
                    />
                  </Tooltip>
                </div>
              </>
            }
            value={p.slug}
            aria-controls={`scrollable-auto-tabpanel-${p.id}`}
          />
        ))}
      </Tabs>

      <Tooltip title="Add path">
        <Fab
          color="primary"
          aria-label="add"
          size="small"
          component={Link}
          to={'/admin/paths//add'}
        >
          <AddIcon />
        </Fab>
      </Tooltip>
    </div>
  );
};

export default PathTabs;
