import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { Check as CheckIcon, Save as SaveIcon } from '@material-ui/icons';
import { Fab, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative'
    },
    buttonSuccess: {
      backgroundColor: green[500],
      '&:hover': {
        backgroundColor: green[700]
      }
    },
    fabProgress: {
      color: green[500],
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 1
    }
  })
);

type Sizes = 'small' | 'medium';

export interface CircularLoadingButtonProps {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  loading: boolean;
  success?: boolean;
  size?: Sizes;
  icon?: React.ReactNode;
}

const sizeMap: { [key in Sizes]: number } = {
  small: 40,
  medium: 48
};

const CircularLoadingButton = ({
  onClick,
  loading,
  success,
  size = 'medium',
  icon
}: CircularLoadingButtonProps) => {
  const classes = useStyles();

  const buttonClassname = clsx({
    [classes.buttonSuccess]: success
  });

  return (
    <div className={classes.wrapper}>
      <Fab
        aria-label="save"
        color="primary"
        className={buttonClassname}
        onClick={onClick}
        disabled={loading}
        size={size}
      >
        {success ? <CheckIcon /> : icon || <SaveIcon />}
      </Fab>
      {loading && (
        <CircularProgress size={sizeMap[size]} className={classes.fabProgress} />
      )}
    </div>
  );
};

export default CircularLoadingButton;
