import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  AddOrUpdateUserRequest,
  PathDto,
  ProfileDto,
  ApiResponse
} from '../../api/coach.generated';
import { useFormHandler, FormHandler } from '../../helpers/FormHandler';
import { DialogContent } from '@material-ui/core';
import { CoachDialogActions } from '../../components/CoachDialog';
import { SelectListItem } from '../../components/SelectListItem';

const useStyles = makeStyles((theme) => ({
  form: {
    paddingLeft: 30,
    paddingRight: 30,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  groupHeading: {
    margin: '15px 0 -15px 0'
  }
}));

const rolesThatCanBeChanged = ['Admin'];

const Form = ({
  profile,
  allPaths,
  allRoles,
  usersList,
  endpoint,
  onSuccess
}: {
  profile?: ProfileDto;
  allPaths: PathDto[];
  allRoles: string[];
  usersList: SelectListItem[];
  endpoint(model: AddOrUpdateUserRequest): Promise<ApiResponse | null>;
  onSuccess(): void;
}) => {
  const classes = useStyles();

  const formHandler: FormHandler<AddOrUpdateUserRequest> = useFormHandler({
    model: {
      ...(profile as ProfileDto),
      pathIds: profile?.paths.map(p => p.id) ?? [],
      roles: profile?.roles ?? []
    },
    endpoint: () => endpoint(formHandler.state.model),
    onSuccess: onSuccess
  });

  return (
    <>
      <DialogContent>
        <form className={classes.form} autoComplete="off">
          {formHandler.GenericError()}
          {formHandler.FormField({ propName: 'firstName', required: true })}
          {formHandler.FormField({ propName: 'lastName', required: true })}
          {formHandler.FormField({ propName: 'email', required: true })}
          {formHandler.FormField({
            propName: 'reportsToId',
            label: 'Reports to',
            type: 'dropdown',
            list: usersList
          })}
          {formHandler.FormField({ propName: 'position' })}
          {formHandler.FormField({ propName: 'team' })}
          {formHandler.FormField({
            propName: 'pathIds',
            label: 'Paths',
            type: 'checklist',
            list: allPaths.map((path) => ({
              id: path.id,
              label: path.name
            }))
          })}
          {formHandler.FormField({
            propName: 'roles',
            label: 'Roles',
            type: 'checklist',
            list: allRoles
              .filter((role) => rolesThatCanBeChanged.includes(role))
              .map((role) => ({
                id: role,
                label: role,
                selected: !!profile?.roles.includes(role)
              }))
          })}
        </form>
      </DialogContent>
      <CoachDialogActions
        onSubmit={formHandler.execute}
        actionInProgress={formHandler.state.inProgress}
      />
    </>
  );
};

export default Form;
