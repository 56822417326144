import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Avatar, Typography } from '@material-ui/core';
import useAppContext from '../../hooks/useAppContext';

const useStyles = makeStyles((theme) => ({
  root: {},
  details: {
    display: 'flex'
  },
  avatar: {
    marginLeft: 'auto',
    height: 110,
    width: 100,
    flexShrink: 0,
    flexGrow: 0
  },
  name: {
    marginBottom: 50
  },
  progress: {
    marginTop: theme.spacing(2)
  },
  uploadButton: {
    marginRight: theme.spacing(2)
  }
}));

const AccountProfile = ({ className, ...rest }: any) => {
  const classes = useStyles();
  const { profile } = useAppContext();

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <div className={classes.details}>
          <div>
            <Typography gutterBottom variant="h2" className={classes.name}>
              {profile?.fullName}
            </Typography>
            <Typography gutterBottom color="textSecondary" variant="body1">
              Position: <b>{profile?.position}</b>
            </Typography>
            <Typography gutterBottom color="textSecondary" variant="body1">
              Team: <b>{profile?.team}</b>
            </Typography>
            <Typography gutterBottom color="textSecondary" variant="body1">
              Email: <b>{profile?.email}</b>
            </Typography>
          </div>
          <Avatar className={classes.avatar}>
            {`${profile?.firstName[0]}${profile?.lastName[0]}`}
          </Avatar>
        </div>
      </CardContent>
    </Card>
  );
};

export default AccountProfile;
