import React from 'react';
import { InputAdornment, IconButton, FormControl, Input } from '@material-ui/core';
import { Clear as ClearIcon } from '@material-ui/icons';

const SearchTextField = ({ callback }: { callback(val: string): void }) => {
  const [value, setValue] = React.useState('');
  const timeoutRef = React.useRef<number>();

  React.useEffect(() => {
    return () => window.clearTimeout(timeoutRef.current);
  }, []);

  const onChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      setValue(e.target.value);
      window.clearTimeout(timeoutRef.current);
      timeoutRef.current = window.setTimeout(() => callback(val), 200);
    },
    [callback]
  );

  const clear = React.useCallback(() => {
    setValue('');
    callback('');
  }, [callback]);

  return (
    <FormControl style={{ display: 'flex' }}>
      <Input
        placeholder="Search.."
        value={value}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton size="small" onClick={clear}>
              <ClearIcon style={{ width: 16, height: 16 }} />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export default SearchTextField;
