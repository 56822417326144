import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Route } from 'react-router-dom';
import { Paper, Divider } from '@material-ui/core';
import Goals from './Goals';
import Pathways from './Pathways';
import Reviews from './Reviews';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: { margin: theme.spacing(2) }
  })
);

const Dashboard = () => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <Goals />
      <Divider variant="middle" />
      <Pathways />
      <Route path="/dashboard/reviews/:courseId" component={Reviews} />
    </Paper>
  );
};

export default Dashboard;
