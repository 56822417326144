import React from 'react';
import { withRouter } from 'react-router-dom';
import { Typography, makeStyles } from '@material-ui/core';
import { AuthApi } from '../api/coachApi';
import { handleErrors } from '../api/errorHandler';
import { session } from '../helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  h1: {
    textAlign: 'center',
    color: theme.palette.grey[600],
    padding: theme.spacing(3)
  }
}));

const Logout = ({ history }: any) => {
  const classes = useStyles();

  React.useEffect(() => {
    (async () => {
      try {
        const response = (await AuthApi.logOut())!;
        session.removeUserId();
        if (response.success) {
          return history.push('/login');
        }
        alert('Login failed');
      } catch (error) {
        handleErrors(error);
      }
    })();
  }, [history]);
  return (
    <div className={classes.root}>
      <Typography variant="h1" className={classes.h1}>
        Logging you out...
      </Typography>
    </div>
  );
};

export default withRouter(Logout);
