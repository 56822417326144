export const camelToCapitalized = (camelCase: any) => {
  if (!camelCase) return '';

  return camelCase
    .replace(/([A-Z])/g, (match: any) => ` ${match}`)
    .replace(/^./, (match: any) => match.toUpperCase())
    .trim();
};

export default camelToCapitalized;
