import React from 'react';
import DragHandleIcon from '@material-ui/icons/DragHandle';
import { makeStyles } from '@material-ui/core/styles';
import { PathsApi } from '../../../api/coachApi';
import { CourseInfo } from '../../../api/coach.generated';
import LoadingSkeleton from '../../../components/LoadingSkeleton';
import { useLocation, useParams } from 'react-router-dom';
import Course from './Course';
import { Draggable, DraggableProvided, Droppable, DropResult } from 'react-beautiful-dnd';
import usePathContext from '../usePathContext';
import reorder from '../../../helpers/reorder';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 20
  },
  course: {
    display: 'flex'
  },
  dragHandle: {
    position: 'relative',
    top: 6,
    marginRight: 5
  }
}));

export default function Courses({
  skillId,
  canEdit,
  count
}: {
  skillId: string;
  canEdit: boolean;
  count: number;
}) {
  const classes = useStyles();
  const { pathSlug } = useParams<{ pathSlug: string }>();
  const { pathname } = useLocation();
  const { dragEndCallbacks } = usePathContext();
  const [isLoading, setIsLoading] = React.useState(false);
  const [courses, setCourses] = React.useState<CourseInfo[]>([]);

  const fetchCourses = React.useCallback(async () => {
    const coursesRes = await PathsApi.getCoursesBySkill(pathSlug, skillId);
    coursesRes && setCourses(coursesRes);
  }, [pathSlug, skillId]);

  React.useEffect(() => {
    const fetch = async () => {
      setIsLoading(true);
      await fetchCourses();
      setIsLoading(false);
    };
    fetch();
  }, [fetchCourses, pathname]);

  const updateRanking = React.useCallback(
    (result: DropResult) => {
      if (!result || result.type !== skillId) return;
      if (!result.destination) return;

      const reorderedCourses = reorder(
        courses,
        result.source.index,
        result.destination.index
      );

      const rankings: { [key: string]: number } = {};
      reorderedCourses.forEach((val, idx) => {
        rankings[val.id] = idx;
      });

      setCourses(reorderedCourses);
      PathsApi.updateCourseRankings({
        pathSlug: pathSlug,
        courseRankings: rankings
      });
    },
    [courses, pathSlug, skillId]
  );

  React.useEffect(() => {
    const cb = dragEndCallbacks.current;
    cb[skillId] = updateRanking;
    return () => {
      delete cb[skillId];
    };
  }, [dragEndCallbacks, skillId, updateRanking]);

  const onRemoved = (courseId: string): void => {
    courses && setCourses(courses.filter((c) => c.id !== courseId));
  };

  return (
    <div className={classes.root}>
      <Droppable droppableId={skillId} type={skillId}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {isLoading && <LoadingSkeleton variant="list" rows={count} />}
            {!isLoading && !courses.length && <span>No courses</span>}
            {courses.map((course, idx) => (
              <Draggable
                key={course.id}
                draggableId={`drag-${course.id}`}
                index={idx}
                isDragDisabled={!canEdit}
              >
                {(provided: DraggableProvided) => (
                  <div
                    className={classes.course}
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                  >
                    <span style={{}} {...provided.dragHandleProps}>
                      <DragHandleIcon className={classes.dragHandle} />
                    </span>
                    <Box key={course.id} mb={2}>
                      <Course
                        key={course.id}
                        course={course}
                        onRemoved={onRemoved}
                        canEdit={canEdit}
                      />
                    </Box>
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
}
