import React from 'react';
import { useParams } from 'react-router-dom';
import {
  CourseGoalDto,
  ProfileDto,
  SkillStatsDto,
  UserPathStatsDto
} from '../../api/coach.generated';
import { DashboardApi, UsersApi } from '../../api/coachApi';
import useRequest, { Request } from '../../hooks/useRequest';

export type DashboardContextType = {
  selectedPath?: UserPathStatsDto;
  pathsRequest: Request<UserPathStatsDto[] | null>;
  goalsRequest: Request<CourseGoalDto[] | null>;
  skillsRequest: Request<SkillStatsDto[] | null>;
};

export const DashboardContext = React.createContext<DashboardContextType>(null!);

const DashboardProvider: React.FC<{ profile: ProfileDto }> = ({ profile, children }) => {
  const { pathSlug } = useParams<{ pathSlug: string }>();
  const [selectedPath, setSelectedPath] = React.useState<UserPathStatsDto>();

  const getPaths = React.useCallback(() => DashboardApi.userPaths(), []);
  const pathsRequest = useRequest(getPaths);

  const getGoals = React.useCallback(() => UsersApi.getGoals(profile.id), [profile]);
  const goalsRequest = useRequest(getGoals);

  const getSkills = React.useCallback(
    () =>
      selectedPath ? DashboardApi.skills(selectedPath.pathId) : Promise.resolve(null),
    [selectedPath]
  );
  const skillsRequest = useRequest(getSkills);

  React.useEffect(() => {
    setSelectedPath(pathsRequest.result?.find((p) => p.pathSlug === pathSlug));
  }, [pathsRequest.result, pathSlug]);

  return (
    <DashboardContext.Provider
      value={{
        selectedPath,
        pathsRequest,
        goalsRequest,
        skillsRequest
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};

export default DashboardProvider;
