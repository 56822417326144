import * as React from 'react';
import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import Fade from '@material-ui/core/Fade';

const LoadingSkeleton = ({ variant, rows }: { variant: 'table' | 'list', rows?: number }): JSX.Element => {
  switch (variant) {
    case 'table':
      return TableSkeleton();

    case 'list':
      return ListSkeleton(rows ?? 5);
  }
};

const ListSkeleton = (rows: number) => {
  return (
    <Fade in>
      <Box>
        {Array.from(Array(rows).keys()).map((key) => (
          <div key={key} style={{ marginBottom: 5 }}>
            <div style={{ display: 'inline-block', position: 'relative', top: 5 }}>
              <Skeleton variant="circle" animation="wave" width={20} height={20} />
            </div>
            <div
              style={{
                display: 'inline-block',
                padding: 10,
                width: 800
              }}
            >
              <Skeleton animation="wave" height={10} width="100%" />
            </div>
          </div>
        ))}
      </Box>
    </Fade>
  );
};

const TableSkeleton = (): JSX.Element => {
  return (
    <div style={{ padding: '0 0 30px 30px' }}>
      {Array(6)
        .fill(0)
        .map((value, idx) => (
          <Box key={idx}>
            <Skeleton
              height={60}
              width={350}
              style={{ display: 'inline-block', marginRight: 20 }}
            />
            <Skeleton
              height={60}
              width={450}
              style={{ display: 'inline-block', marginRight: 20 }}
            />
            <Skeleton height={60} width={250} style={{ display: 'inline-block' }} />
          </Box>
        ))}
    </div>
  );
};

export default LoadingSkeleton;
