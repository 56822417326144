import React from 'react';
import { Switch, Redirect, Route } from 'react-router';
import RouteWithLayout from './RouteWithLayout';
import MainLayout from './layouts/main';
import DashboardPage from './views/Dashboard/DashboardPage';
import UserList from './views/People/List';
import Paths from './views/Paths';
import MinimalLayout from './layouts/MinimalLayout';
import NotFound from './views/NotFound';
import Login from './views/Login';
import RequestReset from './views/RecoverPassword/RequestReset';
import SetNewPassword from './views/RecoverPassword/SetNewPassword';
import Register from './views/Register';
import Logout from './views/Logout';
import Settings from './views/Settings';
import Account from './views/Account';
import Activity from './views/Activity';
import { session } from './helpers';

const Routes = () => {
  return (
    <Switch>
      <Route exact path="/">
        {session.userId ? <Redirect to="/dashboard" /> : <Redirect to="/login" />}
      </Route>

      <RouteWithLayout component={Activity} layout={MainLayout} path="/activity" />
      <RouteWithLayout
        component={DashboardPage}
        layout={MainLayout}
        path="/dashboard/:pathSlug?"
      />

      <RouteWithLayout component={UserList} layout={MainLayout} path="/admin/users" />

      <RouteWithLayout
        component={Paths}
        layout={MainLayout}
        path="/admin/paths/:pathSlug?"
      />

      <RouteWithLayout
        component={Register}
        exact
        layout={MinimalLayout}
        path="/register"
      />
      <RouteWithLayout component={Settings} exact layout={MainLayout} path="/settings" />
      <RouteWithLayout component={Account} exact layout={MainLayout} path="/account" />
      <RouteWithLayout component={Login} exact layout={MinimalLayout} path="/login" />
      <RouteWithLayout
        component={RequestReset}
        exact
        layout={MinimalLayout}
        path="/recover-password"
      />
      <RouteWithLayout
        component={SetNewPassword}
        exact
        layout={MinimalLayout}
        path="/new-password"
      />
      <RouteWithLayout component={Logout} exact layout={MinimalLayout} path="/logout" />
      <RouteWithLayout
        component={NotFound}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default Routes;
