import React from 'react';
import { DialogActions, Tooltip, makeStyles } from '@material-ui/core';
import CircularLoadingButton from '../CircularLoadingButton';

const useStyles = makeStyles(theme => ({
  actions: {
    margin: theme.spacing(1)
  }
}));

const CoachDialogActions = ({
  onSubmit,
  actionInProgress,
  success
}: {
  onSubmit: () => void;
  actionInProgress: boolean;
  success?: boolean;
}) => {
  const classes = useStyles();

  return (
    <DialogActions className={classes.actions}>
      <Tooltip title="Save" aria-label="add">
        <span>
          <CircularLoadingButton
            onClick={onSubmit}
            loading={actionInProgress}
            success={success}
          />
        </span>
      </Tooltip>
    </DialogActions>
  );
};

export default CoachDialogActions;
