import { makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

export default makeStyles((theme) => ({
  root: { margin: theme.spacing(2) },
  toolbar: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1)
  },
  title: {
    flex: '1 1 100%'
  },
  table: { minWidth: 350 },
  actions: { visibility: 'hidden' },
  row: {
    '&:hover .actions': {
      visibility: 'visible'
    }
  },
  downArrow: {
    width: 16,
    marginTop: 0,
    position: 'relative',
    top: 6
  },
  red: { color: theme.palette.error.dark },
  green: { color: green[500] },
  path: {
    marginLeft: '1rem',
    '&:first-of-type': { margin: 0 }
  }
}));
