import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, CardActions, Typography } from '@material-ui/core';
import CircularLoadingButton from '../../components/CircularLoadingButton';
import { UpdatePasswordRequest } from '../../api/coach.generated';
import { useFormHandler } from '../../helpers';
import { AccountApi } from '../../api/coachApi';
import { FormHandler } from '../../helpers/FormHandler';

const useStyles = makeStyles(() => ({
  root: {},
  action: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

const Password = ({ className, ...rest }: any) => {
  const classes = useStyles();

  const formHandler: FormHandler<UpdatePasswordRequest> = useFormHandler({
    model: {} as UpdatePasswordRequest,
    endpoint: () => AccountApi.updatePassword(formHandler.state.model)
  });

  const handleSubmit = async () => {
    await formHandler.execute();
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off">
        <CardContent>
          <Typography gutterBottom variant="h6">
            Manage password
          </Typography>
          {formHandler.GenericError()}
          {formHandler.FormField({
            propName: 'currentPassword',
            type: 'password',
            label: 'Current Password'
          })}
          {formHandler.FormField({
            propName: 'newPassword',
            type: 'password',
            label: 'New Password'
          })}
        </CardContent>
        <CardActions className={classes.action}>
          <CircularLoadingButton
            onClick={handleSubmit}
            loading={formHandler.state.inProgress}
            success={formHandler.state.success}
          />
        </CardActions>
      </form>
    </Card>
  );
};

export default Password;
