import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Form from './Form';
import { CoachDialogWithTitle } from '../../CoachDialog';
import { toaster } from '../../Toaster';
import { ApiResponse, CreateOrUpdateCourseRequest } from '../../../api/coach.generated';
import { CoursesApi, PathsApi } from '../../../api/coachApi';
import { DialogContent, makeStyles } from '@material-ui/core';
import SearchBar from '../../SearchBar';

const useStyles = makeStyles((theme) => ({
  form: {
    paddingLeft: 30,
    paddingRight: 30,
    marginBottom: theme.spacing(2),
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  }
}));

const New = ({ term, onSuccess }: { term: string; onSuccess(): void }) => {
  const history = useHistory();
  const { pathSlug } = useParams<{ pathSlug: string }>();
  const classes = useStyles();

  const [course, setCourse] = React.useState<CreateOrUpdateCourseRequest>();
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (term?.startsWith('http')) search(term);
  }, [term]);

  const search = async (url: string) => {
    setLoading(true);
    const res = await CoursesApi.getMeta(url);
    if (res) {
      setCourse((res as unknown) as CreateOrUpdateCourseRequest);
    }
    setLoading(false);
  };

  const success = () => {
    onSuccess();
    history.push(`/admin/paths/${pathSlug}`);
    toaster.success('Course added');
  };

  const close = () => {
    history.push(`/admin/paths/${pathSlug}`);
  };

  const endpoint = (model: CreateOrUpdateCourseRequest): Promise<ApiResponse | null> =>
    PathsApi.addNewCourse(pathSlug!, { ...model });

  const renderBody = () => {
    if (loading)
      return (
        <DialogContent>
          <div className={classes.form}>Loading...</div>
        </DialogContent>
      );
    if (course) return <Form course={course} onSuccess={success} endpoint={endpoint} />;
    return (
      <DialogContent>
        <div className={classes.form}>
          <SearchBar label="Url" onSearch={search} />
        </div>
      </DialogContent>
    );
  };

  return (
    <CoachDialogWithTitle
      title="Add new course"
      open
      onClose={close}
      inProgress={loading}
    >
      {renderBody()}
    </CoachDialogWithTitle>
  );
};

export default New;
