import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CoursesApi, PathsApi } from '../../../api/coachApi';
import { CourseDto } from '../../../api/coach.generated';
import { toaster } from '../../../components/Toaster';
import { CoachDialogWithTitle } from '../../CoachDialog';
import {
  DialogContent,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchBar from '../../SearchBar';
import DisplayCourse from '../../DisplayCourse';

const useStyles = makeStyles((theme) => ({
  spacing: {
    marginTop: theme.spacing(3)
  },
  spacingLeft: {
    marginLeft: theme.spacing(3)
  },
  th: {
    textAlign: 'right',
    color: theme.palette.text.hint
  }
}));

const Search = ({
  setSearchTerm,
  onClickAddNew,
  onSuccess
}: {
  setSearchTerm(term: string): void;
  onClickAddNew(): void;
  onSuccess(): void;
}) => {
  const classes = useStyles();

  const history = useHistory();
  const { pathSlug } = useParams<{ pathSlug: string }>();
  const [searchDone, setSearchDone] = React.useState(false);
  const [courseCandidates, setCourseCandidates] = React.useState<CourseDto[]>([]);
  const [course, setCourse] = React.useState<CourseDto>();
  const [inProgress, setInProgress] = React.useState(false);

  const close = () => {
    history.push(`/admin/paths/${pathSlug}`);
  };

  const doSearch = async (keyword: string) => {
    setSearchTerm(keyword);
    setInProgress(true);
    const res = await CoursesApi.search({ keyword, pageSize: 5 });
    if (res) {
      setCourseCandidates(res);
      setCourse(res[0]);
    }
    setSearchDone(true);
    setInProgress(false);
  };

  const addSelected = async () => {
    if (!course || !pathSlug) return;

    setInProgress(true);
    const res = await PathsApi.attachCourse(pathSlug, course.id);
    if (res?.success) {
      onSuccess();
      history.push(`/admin/paths/${pathSlug}`);
      toaster.success('Course added');
    }
    setInProgress(false);
  };

  return (
    <CoachDialogWithTitle
      title="Add course"
      open
      onClose={close}
      loading={false}
      inProgress={inProgress}
    >
      {
        <DialogContent style={{ paddingBottom: '2rem' }}>
          <SearchBar onSearch={doSearch} placeholder="Partial name or url will do" />
          {searchDone && !courseCandidates.length && (
            <h3 className={classes.spacing}>No matches found</h3>
          )}
          {!!courseCandidates.length && (
            <div className={classes.spacing}>
              <h3 className={classes.spacing}>Matches</h3>

              <RadioGroup value={course?.id ?? ''} aria-label="courses">
                {courseCandidates.map((course) => (
                  <FormControlLabel
                    key={course.id}
                    title={course.url}
                    value={course.id}
                    onClick={() => setCourse(course)}
                    control={<Radio />}
                    label={course.name}
                  />
                ))}
              </RadioGroup>
            </div>
          )}
          {course && (
            <>
              <h3 className={classes.spacing}>Selected</h3>
              <DisplayCourse course={course} />
            </>
          )}
          {searchDone && (
            <div className={classes.spacing}>
              {course && (
                <Button
                  variant="contained"
                  onClick={addSelected}
                  color="primary"
                  disabled={inProgress}
                >
                  Add selected
                </Button>
              )}
              <Button
                //onClick={() => history.push(`/dashboard/paths/${pathSlug}/addNew`)}
                onClick={onClickAddNew}
                className={classes.spacingLeft}
                variant="contained"
                disabled={inProgress}
              >
                Add new
              </Button>
            </div>
          )}
        </DialogContent>
      }
    </CoachDialogWithTitle>
  );
};

export default Search;
