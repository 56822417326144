import * as React from 'react';
import { TableContainer, Table, TableRow, TableCell, TableBody } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import { CourseDto } from '../api/coach.generated';

const useStyles = makeStyles((theme) => ({
  selectedTableContainer: {
    marginTop: theme.spacing(1)
  },
  th: {
    textAlign: 'right',
    color: theme.palette.text.hint
  }
}));

const DisplayCourse = ({ course }: { course: CourseDto }) => {
  const classes = useStyles();
  return (
    <TableContainer className={classes.selectedTableContainer} component={Paper}>
      <Table size="small" style={{ minWidth: '34rem' }}>
        <TableBody>
          <TableRow>
            <TableCell className={classes.th} component="th" scope="row">
              Name
            </TableCell>
            <TableCell>{course.name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.th} component="th" scope="row">
              Url
            </TableCell>
            <TableCell>{course.url}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.th} component="th" scope="row">
              Skill
            </TableCell>
            <TableCell>
              {course.skill.category}: {course.skill.name}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell className={classes.th} component="th" scope="row">
              Duration
            </TableCell>
            <TableCell>{course.durationSeconds}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DisplayCourse;
