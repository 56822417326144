import React, { Component } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import theme from './theme';
import Routes from './Routes';
import moment from 'moment';
require('moment/locale/et');
moment.locale('et');

export default class App extends Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Routes />
          </Switch>
        </Router>
      </ThemeProvider>
    );
  }
}
