import React from 'react';
import clsx from 'clsx';
import { green } from '@material-ui/core/colors';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import {
  KeyboardArrowRight as KeyboardArrowRightIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon
} from '@material-ui/icons';
import { SkillStatsDto } from '../../../api/coach.generated';
import Courses from './Courses';
import toPercent from '../../../helpers/toPercent';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    arrow: {
      position: 'relative',
      top: 6
    },
    done: {
      color: green[500],
      fontWeight: 700
    }
  })
);

const Skill = ({ skill }: { skill: SkillStatsDto }) => {
  const classes = useStyles();
  const [showCourses, setShowCourses] = React.useState(false);

  return (
    <div
      style={{ margin: '20px 10px 0 10px', cursor: 'pointer' }}
      onClick={() => setShowCourses(!showCourses)}
    >
      {showCourses ? (
        <KeyboardArrowDownIcon className={classes.arrow} />
      ) : (
        <KeyboardArrowRightIcon className={classes.arrow} />
      )}
      <span style={{ color: '#999', fontSize: '.8rem', position: 'relative', top: -1 }}>
        {skill.category} »{' '}
      </span>
      <b>{skill.name}</b>{' '}
      <span className={clsx({ [classes.done]: skill.totalCompleted === skill.total })}>
        {' '}
        ({toPercent(skill.totalCompleted, skill.total)}%)
      </span>
      {showCourses && (
        <div style={{ marginLeft: 15 }}>
          <Courses skillId={skill.id} count={skill.total} />
        </div>
      )}
    </div>
  );
};

export default Skill;
