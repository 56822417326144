import React from 'react';
import { useHistory } from 'react-router-dom';
import { UsersApi, PathsApi } from '../../api/coachApi';
import {
  AddOrUpdateUserRequest,
  ApiResponse,
  PathDto
} from '../../api/coach.generated';
import { toast } from '../../components/Toaster';
import Form from './Form';
import { CoachDialogWithTitle } from '../../components/CoachDialog';
import { SelectListItem } from '../../components/SelectListItem';

const Add = () => {
  const history = useHistory();
  const [paths, setPaths] = React.useState<PathDto[] | null>(null);
  const [usersList, setUsersList] = React.useState<SelectListItem[] | null>(null);

  React.useEffect(() => {
    const fetchData = async () => {
      const [pathsRes, usersListRes] = await Promise.all([
        PathsApi.getAll(),
        UsersApi.getNames()
      ]);

      setPaths(pathsRes);
      setUsersList(usersListRes?.map((u) => ({ id: u.key, label: u.value })) ?? null);
    };
    fetchData();
  }, []);

  const success = () => {
    history.push('/admin/users');
    toast({ message: 'User added', variant: 'success' });
  };

  const close = () => {
    history.push('/admin/users');
  };

  const endpoint = (model: AddOrUpdateUserRequest): Promise<ApiResponse | null> =>
    UsersApi.post(model);

  return (
    <CoachDialogWithTitle title="Add user" open onClose={close} loading={!paths}>
      {paths && usersList && (
        <Form
          allPaths={paths}
          allRoles={[]}
          usersList={usersList}
          onSuccess={success}
          endpoint={endpoint}
        />
      )}
    </CoachDialogWithTitle>
  );
};

export default Add;
