import React from 'react';
import { makeStyles, IconButton, CircularProgress, Tooltip } from '@material-ui/core';
import { DeleteOutline as DeleteOutlineIcon } from '@material-ui/icons';
import { UsersApi } from '../../api/coachApi';
import useRequest from '../../hooks/useRequest';

const useStyles = makeStyles((theme) => ({
  root: { position: 'relative', display: 'inline-block' },
  progress: { position: 'absolute', top: 9, left: 9 }
}));

const Delete = ({
  profileId,
  disabled,
  onDeleted
}: {
  profileId: string;
  disabled: boolean;
  onDeleted: (profileId: string) => void;
}) => {
  const classes = useStyles();
  const deleteUserRequest = useRequest(
    (userId) => UsersApi.delete(userId),
    {
      lazy: true
    }
  );

  const handleDelete = async (id: string) => {
    if (!window.confirm('Are you sure?')) return false;

    await deleteUserRequest.execute(id);
    !deleteUserRequest.errorMessage && onDeleted(id);
  };

  return (
    <div className={classes.root}>
      <Tooltip title={disabled ? 'No rights' : 'Delete'} aria-label="add">
        <span>
          <IconButton
            disabled={disabled || deleteUserRequest.loading}
            onClick={() => handleDelete(profileId)}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </span>
      </Tooltip>
      {deleteUserRequest.loading && (
        <CircularProgress size={30} className={classes.progress} />
      )}
    </div>
  );
};

export default Delete;
