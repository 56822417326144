import useRequest, { Request } from './useRequest';

/**
 * Lazy version of the @see useRequest hook
 */
const useLazyRequest = <T>(
  func: (...args: any[]) => Promise<T>,
  successMessage?: string
): Request<T> => {
  return useRequest(func, { successMessage, lazy: true });
};

export default useLazyRequest;
