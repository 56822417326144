import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, LinearProgress } from '@material-ui/core';
import { usePromiseTracker } from 'react-promise-tracker';
import Topbar from './Topbar';
import Footer from './Footer';
import Sidebar from './Sidebar';
import { ToastContainer } from '../../components/Toaster';
import AppProvider from '../../components/AppProvider';

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: 56,
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      paddingTop: 64
    }
  },
  shiftContent: {
    paddingLeft: 240
  },
  content: {
    height: '100%'
  }
}));

const MainLayout = (props: any) => {
  const { children } = props;

  const classes = useStyles();
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
    defaultMatches: true
  });

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const shouldOpenSidebar = isDesktop ? true : openSidebar;
  const { promiseInProgress } = usePromiseTracker();

  return (
    <AppProvider>
      <div
        className={clsx({
          [classes.root]: true,
          [classes.shiftContent]: isDesktop
        })}
      >
        <Topbar onSidebarOpen={handleSidebarOpen} />
        <Sidebar
          onClose={handleSidebarClose}
          open={shouldOpenSidebar}
          variant={isDesktop ? 'persistent' : 'temporary'}
        />
        <main className={classes.content}>
          {promiseInProgress && <LinearProgress style={{}} color="secondary" />}
          {children}
          <Footer />
        </main>
        <ToastContainer />
      </div>
    </AppProvider>
  );
};

export default MainLayout;
