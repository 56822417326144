import React from 'react';
import useStyles from './useStyles';
import { withRouter } from 'react-router-dom';
import { Button, Grid, Typography } from '@material-ui/core';
import { FormHandler } from '../../helpers/FormHandler';
import { useFormHandler } from '../../helpers';
import { RecoverPasswordRequest } from '../../api/coach.generated';
import { AuthApi } from '../../api/coachApi';

const RequestReset = (props: any) => {
  const classes = useStyles();
  const [submitEnabled, setSubmitEnabled] = React.useState(true);

  const formHandler: FormHandler<RecoverPasswordRequest> = useFormHandler({
    model: {} as RecoverPasswordRequest,
    endpoint: () =>
      AuthApi.recoverPassword({
        email: formHandler.state.model.email
      }),
    onSuccess: (response) => {}
  });

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setSubmitEnabled(false);
    await formHandler.execute();
    !formHandler.state.success && setSubmitEnabled(true);
  };

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography className={classes.quoteText} variant="h1">
                Knowledge awaits you!
              </Typography>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form
                className={classes.form}
                onSubmit={handleSubmit}
                hidden={formHandler.state.success}
              >
                <Typography className={classes.title} variant="h2">
                  Recover password
                </Typography>
                {formHandler.GenericError()}
                {formHandler.FormField({
                  propName: 'email',
                  label: 'Email address',
                  type: 'text'
                })}
                <Button
                  className={classes.signInButton}
                  color="primary"
                  disabled={!submitEnabled || !formHandler.state.isValid}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Recover
                </Button>
              </form>
              <h4 className={classes.resetSent} hidden={!formHandler.state.success}>
                Email sent to {formHandler.state.model.email}
              </h4>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(RequestReset);
