import React from 'react';
import {
  DialogTitle,
  Typography,
  IconButton,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import { Close as CloseIcon } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500]
  },
  title: {
    display: 'flex',
    alignItems: 'center'
  },
  titleIcon: {
    marginRight: theme.spacing(1)
  }
}));

const CoachDialogTitle = ({
  title,
  inProgress,
  onClose
}: {
  title: string;
  inProgress?: boolean;
  onClose: () => void;
}) => {
  const classes = useStyles();

  return (
    <DialogTitle disableTypography>
      <Typography variant="h6" className={classes.title}>
        {inProgress ? (
          <CircularProgress color="secondary" size={20} className={classes.titleIcon} />
        ) : (
          // <FaceIcon color="primary" className={classes.titleIcon} />
          <></>
        )}
        {title}
      </Typography>
      <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};

export default CoachDialogTitle;
