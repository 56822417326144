import * as React from 'react';
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  IconButton,
  styled
} from '@material-ui/core';
import { EditOutlined as EditOutlinedIcon } from '@material-ui/icons';
import { Link as RouterLink, LinkProps as RouterLinkProps } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Delete from './Delete';
import clsx from 'clsx';
import {
  UserStatsByPathDto,
  CourseInfo,
  CourseGoalDto,
  UserCourseDto
} from '../../api/coach.generated';
import LoadingSkeleton from '../../components/LoadingSkeleton';
import UserPathPreview from './UserPathPreview/UserPathPreview';
import UserStats from './UserStats';
import MiniProfile from './MiniProfile';
import useStyles from './UsersTable.styles';

const StatsContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
});

const Link = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => (
  <RouterLink innerRef={ref} {...props} />
));

const UsersTable = ({
  userStats,
  onRemove,
  coursesByPathAndUserEndpoint,
  userGoalsEndpoint,
  canEdit = false
}: {
  userStats: UserStatsByPathDto[] | undefined;
  onRemove(userId: string): void;
  coursesByPathAndUserEndpoint: (
    pathId: string,
    userId: string
  ) => Promise<CourseInfo[] | null>;
  userGoalsEndpoint: (userId: string) => Promise<CourseGoalDto[] | null>;
  canEdit?: boolean;
}) => {
  const classes = useStyles();

  const [courseInfosForPreview, setCourseInfosForPreview] = React.useState<UserCourseDto[]>(
    []
  );
  const [pathForPreview, setPathForPreview] = React.useState<string>();
  const [previewUser, setPreviewUser] = React.useState<{id: string, name: string}>();
  const [previewOpen, setPreviewOpen] = React.useState(false);

  const viewPath = async (
    event: React.MouseEvent,
    pathName: string,
    userName: string,
    userId: string,
    pathId: string
  ) => {
    event.preventDefault();
    setCourseInfosForPreview([]);
    setPathForPreview(pathName);
    setPreviewUser({ id: userId, name: userName });
    setPreviewOpen(true);

    const courses = await coursesByPathAndUserEndpoint(pathId, userId);
    if (!courses) return;

    setCourseInfosForPreview(courses);
  };

  const viewGoals = async (userName: string, userId: string) => {
    setCourseInfosForPreview([]);
    setPathForPreview('Goals');
    setPreviewUser({ id: userId, name: userName });
    setPreviewOpen(true);

    const goals = await userGoalsEndpoint(userId);
    if (!goals) return;

    setCourseInfosForPreview(goals.map((g) => g.course!));
  };

  return !userStats ? (
    <LoadingSkeleton variant="table" />
  ) : (
    <PerfectScrollbar>
      <UserPathPreview
        open={previewOpen}
        setOpen={setPreviewOpen}
        courseInfos={courseInfosForPreview}
        pathName={pathForPreview}
        user={previewUser}
      />
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          {userStats.map((profile) => (
            <TableRow key={profile.email} hover className={classes.row}>
              <TableCell>
                <MiniProfile
                  profile={profile}
                  onGoalsClick={(e) =>
                    viewGoals(`${profile.firstName} ${profile.lastName}`, profile.userId)
                  }
                />
              </TableCell>
              <TableCell>
                <StatsContainer>
                  {profile.paths.map((path) => {
                    const stats = profile.stats?.find((p) => p.pathId === path.id);
                    return stats && (
                      <UserStats
                        key={stats.pathId}
                        stats={stats}
                        onClick={(e) =>
                          viewPath(
                            e,
                            `${stats.pathName}`,
                            `${profile.firstName} ${profile.lastName}`,
                            profile.userId,
                            stats.pathId
                          )
                        }
                      />
                    );
                  })}
                </StatsContainer>
              </TableCell>
              {canEdit && (
                <TableCell>
                  <div className={clsx(classes.actions, 'actions')}>
                    <Delete
                      profileId={profile.userId}
                      disabled={profile.roles!.includes('Admin')}
                      onDeleted={onRemove}
                    />
                    <Tooltip title="Edit" aria-label="add">
                      <IconButton
                        component={Link}
                        to={`/admin/users/edit/${profile.userId}`}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </PerfectScrollbar>
  );
};

export default UsersTable;
