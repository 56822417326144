import React from 'react';
import DashboardProvider from './DashboardProvider';
import Dashboard from './Dashboard';
import useAppContext from '../../hooks/useAppContext';

const DashboardPage = () => {
  const { profile } = useAppContext();

  if (!profile) return null;

  return (
    <DashboardProvider profile={profile}>
      <Dashboard />
    </DashboardProvider>
  );
};

export default DashboardPage;
